var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cc-pdf" }, [
    _c(
      "div",
      { staticClass: "cc-pdf__toolbar" },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-sm",
            on: {
              click: function($event) {
                return _vm.changePage("prev")
              }
            }
          },
          [_vm._v("«")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm",
            on: {
              click: function($event) {
                return _vm.changePage()
              }
            }
          },
          [_vm._v("»")]
        ),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { attrs: { id: "cc-pdf__viewport-container" } }, [
      _c("div", { ref: "viewport", staticClass: "cc-pdf__viewport" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }