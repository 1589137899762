<script>
    import Model from '../form/FormModel';
    import VueRecaptcha from 'vue-recaptcha';

    export default {
        name: 'reportProblemForm',

        components: { VueRecaptcha },

        data() {
            return {
                model: new Model({
                    description: '',
                    captcha: null,
                }),
                submitting: false,
                submitted: false,
                failedUpload: false,
            };
        },

        methods: {
            onClose() {
                this.model.reset();
                this.submitted = false;
            },

            onVerify(response) {
                this.model.$.captcha = response;
            },

            onExpired() {
                this.model.$.captcha = null;
            },

            resetRecaptcha() {
                this.$refs.invisibleRecaptcha.reset();
                this.model.$.captcha = '';
            },

            onFileSelect() {
                this.model.$.file = this.$refs.file.files[0];
            },

            async submit() {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;
                this.failedUpload = false;

                try {
                    await axios.post('/api/report', this.model.toFormData({}));
                    this.submitted = true;
                } catch (e) {
                    console.error(e);
                    this.model.$e.updateFromResponse(e);
                    this.resetRecaptcha();

                    if (e.response.status === 413) {
                        this.failedUpload = true;
                    }
                }

                this.submitting = false;
            },
        },
    };
</script>
