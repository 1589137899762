<script>

    export default {
        name: 'LoginOrRegister',

        methods: {
            login() {
                document.dispatchEvent(new CustomEvent('modal-open:auth-modal', { detail: { activeTab: 1 } }));
            },

            register() {
                // noinspection JSCheckFunctionSignatures
                document.dispatchEvent(new CustomEvent('modal-open:auth-modal', { detail: { activeTab: 2 } }));
            },

            onPhoneKeyDown(event) {
                if (event.key === undefined || ['Backspace', 'Delete'].indexOf(event.key) >= 0) {
                    return;
                }

                if (['+'].indexOf(event.key) === -1 && (event.key === ' ' || isNaN(Number(event.key)))) {
                    event.preventDefault();
                }
            },
        },
    };
</script>
