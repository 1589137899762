
// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e) {
    e.preventDefault();
}

// eslint-disable-next-line consistent-return
function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
try {
    window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
        // eslint-disable-next-line getter-return
        get() {
            supportsPassive = true;
        },
    }));
// eslint-disable-next-line no-empty
} catch (e) {}

const wheelOpt = supportsPassive ? { passive: false } : false;
const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

function disableScroll() {
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

function enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

(function () {
    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = `expires=${d.toUTCString()}`;
        document.cookie = `${cname}=${cvalue};${expires};path=/`;
    }

    function getCookie(cname) {
        const name = `${cname}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    function eraseCookie(name) {
        document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }

    const necessaryCookiesData = getCookie('necessary_cookies');
    const functionalCookiesData = getCookie('functional_cookies');
    const analyticCookiesData = getCookie('analytic_cookies');
    const cookiesAgreementBar = document.getElementById('cookie-agreement');
    const primaryCookiesAgreementBar = document.getElementById('cookie-primary');
    const functionalCookies = document.getElementsByName('functional_cookies')[0];
    const analyticCookies = document.getElementsByName('analytic_cookies')[0];
    const customCookiesBtn = document.getElementById('cookies-custom');
    const allCookiesBtn = document.getElementById('cookies-all');
    const allCookiesBtnPrimary = document.getElementById('cookies-all-primary');
    const cookieOverlay = document.getElementById('cookie-overlay');

    document.querySelector('.cookie-floater').addEventListener('click', e => {
        e.preventDefault();
        cookiesAgreementBar.classList.toggle('cookie-agreement-open');
    });

    document.querySelector('#open-settings').addEventListener('click', e => {
        e.preventDefault();
        primaryCookiesAgreementBar.classList.toggle('cookie-agreement-open');
        cookiesAgreementBar.classList.toggle('cookie-agreement-open');
    });

    if (!necessaryCookiesData) {
        primaryCookiesAgreementBar.classList.add('cookie-agreement-open');
        cookieOverlay.style.display = 'block';
        disableScroll();
    }

    if (functionalCookiesData) {
        functionalCookies.checked = true;
    }

    if (analyticCookiesData) {
        analyticCookies.checked = true;
    }

    const cookieAgreements = {
        functional_cookies: functionalCookies.checked,
        analytic_cookies: analyticCookies.checked,
    };

    functionalCookies.addEventListener('click', () => {
        cookieAgreements.functional_cookies = functionalCookies.checked;
    });

    analyticCookies.addEventListener('click', () => {
        cookieAgreements.analytic_cookies = analyticCookies.checked;
    });

    allCookiesBtnPrimary.addEventListener('click', () => {
        setCookie('necessary_cookies', 1, 360);
        setCookie('functional_cookies', 1, 360);
        setCookie('analytic_cookies', 1, 360);
        cookieAgreements.functional_cookies = true;
        cookieAgreements.analytic_cookies = true;
        functionalCookies.checked = true;
        analyticCookies.checked = true;
        cookieOverlay.style.display = 'none';
        primaryCookiesAgreementBar.classList.remove('cookie-agreement-open');
        enableScroll();
    });

    allCookiesBtn.addEventListener('click', () => {
        setCookie('necessary_cookies', 1, 360);
        setCookie('functional_cookies', 1, 360);
        setCookie('analytic_cookies', 1, 360);
        cookieAgreements.functional_cookies = true;
        cookieAgreements.analytic_cookies = true;
        functionalCookies.checked = true;
        analyticCookies.checked = true;
        cookieOverlay.style.display = 'none';
        cookiesAgreementBar.classList.remove('cookie-agreement-open');
        enableScroll();
    });

    customCookiesBtn.addEventListener('click', () => {
        setCookie('necessary_cookies', 1, 360);
        if (cookieAgreements.analytic_cookies) {
            setCookie('analytic_cookies', 1, 360);
        } else {
            eraseCookie('analytic_cookies');
        }
        if (cookieAgreements.functional_cookies) {
            setCookie('functional_cookies', 1, 360);
        } else {
            eraseCookie('functional_cookies');
        }
        cookiesAgreementBar.classList.remove('cookie-agreement-open');
        cookieOverlay.style.display = 'none';
        enableScroll();
    });
})();
