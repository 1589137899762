<template>
    <button :class="{'cc-btn--loading': isLoading}" @click="emitClick" class="cc-btn">
        <div class="btn-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="cc-btn__content">
            <slot/>
        </div>
    </button>
</template>

<script>
    export default {
        name: 'Button',

        props: {
            loading: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                isLoading: this.loading,
            };
        },

        watch: {
            loading() {
                if (this.loading) {
                    setTimeout(this.showLoader, 300);
                } else {
                    this.isLoading = false;
                }
            },
        },

        methods: {
            emitClick(e) {
                this.$emit('click', e);
            },

            showLoader() {
                if (this.loading) {
                    this.isLoading = true;
                }
            },
        },
    };
</script>
