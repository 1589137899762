import detectPassiveEvents from 'detect-passive-events';

/**
 * Get scroll top.
 * @return {Number}
 * @private
 */
export function scrollTop() {
    return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
}

/**
 * Get scroll left.
 * @return {Number}
 */
export function scrollLeft() {
    return window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0;
}

/**
 * Calculate element width.
 * @param {Element} el
 * @param {Boolean} withPadding
 * @param {Boolean} withMargin
 * @param {Boolean} withBorder
 * @return {Number}
 */
export function elementWidth(el, withPadding = false, withMargin = false, withBorder = false) {
    const style = el.currentStyle || window.getComputedStyle(el);
    const width = el.offsetWidth;
    const margin = withMargin ? parseFloat(style.marginLeft) + parseFloat(style.marginRight) : 0;
    const padding = withPadding ? parseFloat(style.paddingLeft) + parseFloat(style.paddingRight) : 0;
    const border = withBorder ? parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth) : 0;

    return width + margin + padding + border;
}

/**
 * Get meta from document.
 * @param {String} name
 * @returns {String|null}
 */
export function meta(name) {
    const meta = document.head.querySelector(`meta[name="${name}"]`);

    if (!meta) {
        return null;
    }

    return meta.content;
}

/**
 * Add passive event listener to browser if supported.
 * @param {Element} element
 * @param {String} event
 * @param {EventListener|Function} listener
 */
export function addPassiveEventListener(element, event, listener) {
    if (detectPassiveEvents.hasSupport === true) {
        element.addEventListener(event, listener, { capture: false, passive: true });
    } else if (detectPassiveEvents.hasSupport === false) {
        element.addEventListener(event, listener, false);
    }
}
