var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "cc-btn",
      class: { "cc-btn--loading": _vm.isLoading },
      on: { click: _vm.emitClick }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "cc-btn__content" }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-loader" }, [
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }