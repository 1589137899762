import numeral from 'numeral';

export default function (value, currency = '€', numberFormat = '0,0.00[00]', format = '{money} {currency}') {
    if (!value) {
        return '';
    }

    return format
        .replace('{money}', numeral(value).format(numberFormat))
        .replace('{currency}', currency);
}
