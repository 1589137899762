import Dropdown from '../services/dropdown/Dropdown';

function rebind(el, value) {
    if (el.__dropdown__) {
        el.removeEventListener('click', el.__dropdown__.click);
        delete el.__dropdown__;
    }

    if (value) {
        el.__dropdown__ = {
            click(event) {
                event.preventDefault();
                event.stopImmediatePropagation();

                Dropdown.find(value).toggle(el);
            },
        };

        el.addEventListener('click', el.__dropdown__.click);
    }
}

export default {
    name: 'dropdown',

    inserted(el, binding) {
        rebind(el, binding.value);
    },

    update(el, binding) {
        rebind(el, binding.value);
    },
};
