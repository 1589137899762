<script>
    import Model from '../../form/FormModel';
    import UserOrganization from './UserOrganization';

    export default {
        name: 'userInfo',

        template: '#cc-user-info-template',

        components: {
            'cc-user-organization': UserOrganization,
        },

        data() {
            return {
                model: new Model({
                    email: '',
                    name: '',
                    last_name: '',
                    phone: '',
                }),
                submitting: false,
                submitted: false,
                read_only: true,
                organizations: [],
            };
        },

        computed: {
            hasOrganizations() {
                return this.organizations.length > 0;
            },

            hasUnsavedOrganizations() {
                return this.organizations.find(org => !org.id);
            },
        },

        created() {
            this.load();
        },

        methods: {
            addOrganization() {
                this.organizations.push({});
            },

            onPhoneKeyDown(event) {
                if (event.key === undefined || ['Backspace', 'Delete'].indexOf(event.key) >= 0) {
                    return;
                }

                if (['+'].indexOf(event.key) === -1 && (event.key === ' ' || isNaN(Number(event.key)))) {
                    event.preventDefault();
                }
            },

            load() {
                this.loadProfile();
                this.loadOrganizations();
            },

            async loadProfile() {
                const response = await axios.get('/api/user/profile');
                this.model.setPristine(response.data.data);
            },

            async loadOrganizations() {
                const response = await axios.get('/api/user/organizations');
                this.organizations = response.data.data;
            },

            toggleReadOnly() {
                this.submitted = false;
                this.read_only = !this.read_only;
            },

            async submit() {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;
                try {
                    await axios.post('/api/user/profile', this.model.toFormData(false, ['email']));
                    this.submitted = true;
                    this.read_only = true;
                    this.model.$e.clear();
                    this.model.setPristine(this.model.$);
                } catch (e) {
                    this.model.$e.updateFromResponse(e);
                }

                this.submitting = false;
            },

            cancel() {
                this.read_only = true;
                this.model.restore();
            },
        },
    };
</script>
