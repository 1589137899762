<script>
import VueRecaptcha from 'vue-recaptcha';
import Model from '../form/FormModel';

export default {
        name: 'ContactUsForm',

        components: { VueRecaptcha },

        data() {
            return {
                model: new Model({
                    full_name: '',
                    organisation: '',
                    email: '',
                    phone: '',
                    comment: '',
                }),
                submitting: false,
                submitted: false,
            };
        },

        methods: {
            onClose() {
                this.model.reset();
                this.submitted = false;
            },

            onVerify(response) {
                this.model.$.captcha = response;
            },

            onExpired() {
                this.model.$.captcha = null;
            },

            resetRecaptcha() {
                this.$refs.invisibleRecaptcha.reset();
                this.model.$.captcha = '';
            },

            onFileSelect() {
                this.model.$.file = this.$refs.file.files[0];
            },

            async submit(action) {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;
                this.failedUpload = false;

                try {
                    await axios.post(action, this.model.toFormData({}));
                    this.submitted = true;
                } catch (e) {
                    console.error(e);
                    this.model.$e.updateFromResponse(e);
                    this.resetRecaptcha();
                }

                this.submitting = false;
            },
        },
    };
</script>
