var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { staticClass: "notifications__holder", attrs: { href: _vm.href } },
    [
      _c("i", { staticClass: "icon icon--bell" }),
      _vm._v(" "),
      _vm.count
        ? _c("span", { staticClass: "notifications__count" }, [
            _vm._v(_vm._s(_vm.count))
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }