<script>
    import FormModel from './../form/FormModel';

    export default {
        name: 'Comment',

        template: '#comment-tamplate',

        props: {
            comment: {
                type: Object,
                required: true,
            },
            type: {
                type: String,
                default: 'article',
                validator(value) {
                    return ['article', 'course', 'learning-path'].indexOf(value) >= 0;
                },
            },
            slug: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                replies: [],
                loading: false,
                replyModel: new FormModel({
                    comment: '',
                }),
                successMessage: false,
            };
        },

        created() {
            if (this.comment.replies_count > 0) {
                this.load();
            }
        },

        methods: {
            replyTo() {
                this.$store.commit('replyToComment', this.comment.id);
            },

            cancelReply() {
                this.$store.commit('clearReplyToComment');
            },

            async load() {
                this.loading = true;
                const response = await axios.get(`/api/comments/${this.type}/${this.slug}/${this.comment.id}`);
                this.replies = response.data.data;
                this.loading = false;
            },

            async submitReply() {
                if (this.loading) {
                    return;
                }
                this.loading = true;

                this.replyModel.$e.clear();

                try {
                    await axios.post(`/api/comments/${this.type}/${this.slug}/${this.$store.state.comments.replyToCommentId}`, this.replyModel.$);
                    await this.load();
                    this.replyModel.reset();
                    this.cancelReply();
                } catch (e) {
                    this.replyModel.$e.updateFromResponse(e);
                }

                this.loading = false;
            },

            async destroy(commentId) {
                if (this.loading) {
                    return;
                }
                this.loading = true;

                try {
                    await axios.delete(`/api/comments/delete/${commentId}`);
                    this.$emit('deleted', commentId);
                } catch (e) {
                    console.error(e);
                }

                this.loading = false;
            },

            removeReply(commentId) {
                this.replies = this.replies.filter(it => it.id !== commentId);
                this.comment.replies_count -= 1;
            },
        },
    };
</script>
