<script>
import Model from '../../../form/FormModel';

const userTemplate = { email: '' };
export default {
    name: 'cc-invitation-form',

    data() {
        return {
            model: new Model({
                users: [{ ...userTemplate }],
                comment: '',
            }),
            submitting: false,
            success_message: null,
        };
    },

    methods: {
        addUser() {
            this.model.$.users.push({ ...userTemplate });
        },

        removeUser(index) {
            this.model.$.users.splice(index, 1);
        },

        async submit(url) {
            if (this.submitting) {
                return;
            }

            this.submitting = true;
            this.success_message = null;
            this.model.$e.clear();

            try {
                const response = await axios.post(url, this.model.$);
                this.success_message = response.data.data.message;
                this.model.clear();
            } catch (e) {
                this.model.$e.updateFromResponse(e);
            }

            this.submitting = false;
        },
    },
};
</script>
