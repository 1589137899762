<script>
import Model from '../../../form/FormModel';

export default {
    name: 'cc-license-grant-access-form',

    props: {
        userEmail: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            model: new Model({
                license: null,
            }),

            submitting: false,
        };
    },

    methods: {
        async submit(url) {
            if (this.submitting) {
                return;
            }

            this.submitting = true;
            this.success_message = null;
            this.model.$e.clear();

            const data = this.model.$;
            if (this.userEmail) {
                data.user_email = this.userEmail;
            }

            try {
                await axios.post(url, data);
                this.model.clear();
                window.location.reload();
            } catch (e) {
                this.model.$e.updateFromResponse(e);
            }

            this.submitting = false;
        },
    },
};
</script>
