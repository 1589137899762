/**
 * Moves an array item from one position in an array to another.
 * Note: This is a pure function so a new array will be returned, instead
 * of altering the array argument.
 * @param {Array} array
 * @param {Number} moveIndex
 * @param {Number} toIndex
 * @returns {Array}
 */
export function moveArrayItem(array, moveIndex, toIndex) {
    const item = array[moveIndex];
    const length = array.length;
    const diff = moveIndex - toIndex;

    if (diff > 0) {
        // move left
        return [
            ...array.slice(0, toIndex),
            item,
            ...array.slice(toIndex, moveIndex),
            ...array.slice(moveIndex + 1, length),
        ];
    } else if (diff < 0) {
        // move right
        const targetIndex = toIndex + 1;
        return [
            ...array.slice(0, moveIndex),
            ...array.slice(moveIndex + 1, targetIndex),
            item,
            ...array.slice(targetIndex, length),
        ];
    }
    return array;
}

/**
 * Remove item from array.
 * @param {Array} array
 * @param {*} item
 * @returns {Boolean}
 */
export function removeArrayItem(array, item) {
    const index = array.indexOf(item);
    if (index >= 0) {
        array.splice(index, 1);
        return true;
    }

    return false;
}
