<script>

    import cloneDeep from 'lodash/cloneDeep';

    export default {
        name: 'quizForm',

        props: {
            quizzes: {
                type: Array,
            },
            passedQuizzesData: {
                type: Array,
            },
            successText: {
                required: true,
                type: String,
            },
            failText: {
                required: true,
                type: String,
            },
            failTextOnRetry: {
                required: true,
                type: String,
            },
            downloadPdfLink: {
                required: true,
                type: String,
            },
        },

        created() {
            document.addEventListener('course-quiz', this.findQuiz);
        },

        destroyed() {
            document.removeEventListener('course-quiz', this.findQuiz);
        },

        data() {
            return {
                current_quiz_id: null,
                correctPercentage: 0,
                active_quiz: null,
                active_quiz_last_data: null,
                title: '',
                current_question: {
                    id: null,
                    title: '',
                    explanation: '',
                    answers: [],
                },
                answered_questions: [],
                quiz_length: 0,
                quiz_completed: false,
                show_quiz: false,
                select_error: false,
                id_for_download: null,
            };
        },

        computed: {
            successMessage() {
                return this.successText.replace(':score', this.correctPercentage);
            },

            failMessage() {
                return this.failText.replace(':score', this.correctPercentage);
            },

            failMessageRetry() {
                const d = new Date(this.active_quiz_last_data.updated_at.substr(0, 10));
                const day = d.getDay() > 9 ? d.getDay() : `0${d.getDay()}`;
                const month = d.getMonth() > 9 ? d.getMonth() : `0${d.getMonth()}`;
                const year = d.getFullYear();
                let txt = this.failTextOnRetry.replace(':score', this.active_quiz_last_data.score);
                txt = txt.replace(':date', `${day}-${month}-${year}`);
                return txt;
            },

            downloadLink() {
                return this.downloadPdfLink.replace(':id', this.id_for_download);
            },
        },

        methods: {
            findQuiz(data) {
                if (data && data.detail && data.detail.id) {
                    this.current_quiz_id = data.detail.id;
                    this.setActiveQuiz();

                    if (this.active_quiz) {
                        this.resetQuiz();
                        this.checkIfQuizCompleted();
                        this.showQuiz();
                    }
                }
            },

            checkIfQuizCompleted(completed = true) {
                const quiz = this.passedQuizzesData.find(obj => obj.id === parseInt(this.current_quiz_id));
                if (quiz) {
                    if (quiz.certificate_id) {
                        this.id_for_download = quiz.certificate_id;
                    }

                    this.title = this.active_quiz.title;
                    this.active_quiz_last_data = quiz;
                    this.quiz_completed = completed;
                    this.correctPercentage = quiz.score;
                    this.showQuiz();
                    return true;
                }

                return false;
            },

            async submitAnswer() {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;

                if (this.checkAtLeastOneSelected()) {
                    this.select_error = false;
                    this.answered_questions.push(cloneDeep(this.current_question));

                    if (this.quiz_length === this.answered_questions.length) {
                        try {
                            const response = await axios.get(`/courses/quiz-data/${this.active_quiz.id}`);

                            if (response.data.success && response.data.data) {
                                this.setCorrectIncorrectValues(response.data.data);
                                this.setPercentageOfCorrect();

                                try {
                                    const response = await axios.post('/quizzes/set-quiz-passed', {
                                        quizTranslationId: this.active_quiz.id,
                                        score: this.correctPercentage,
                                        courseTranslationId: this.active_quiz.course_translation_id,
                                    });

                                    if (response.data.success && response.data.data.passed_quiz) {
                                        this.addUniquePassedData(response.data.data.passed_quiz);
                                    }

                                    if (response.data.success && response.data.data.set_success) {
                                        if (response.data.data.did_success) {
                                            this.setRelatedQuizzesAsPassed(this.active_quiz.id);

                                            if (response.data.data.certificate_id) {
                                                this.id_for_download = response.data.data.certificate_id;
                                            }
                                        } else {
                                            this.setRelatedQuizzesAsTaken(this.active_quiz.id);
                                        }
                                    }
                                } catch (e) {
                                    console.error(e);
                                }
                            }
                        } catch (e) {
                            console.error(e);
                        }

                        this.quiz_completed = true;
                    } else {
                        this.generateQuiz();
                    }
                } else {
                    this.select_error = true;
                }

                this.submitting = false;
            },

            resetQuiz() {
                this.questions = [];
                this.answered_questions = [];
                this.current_question = {
                    id: null,
                    title: '',
                    answers: [],
                };

                this.quiz_completed = false;
                this.forced_complete = false;
                this.quiz_length = 0;

                this.setActiveQuiz();
                this.generateQuiz();
                this.checkIfQuizCompleted(false);
            },

            setActiveQuiz() {
                if (this.current_quiz_id) {
                    const quiz = this.quizzes.find(obj => obj.id === parseInt(this.current_quiz_id));
                    if (quiz) {
                        this.active_quiz = cloneDeep(quiz);
                    }
                }
            },

            generateQuiz() {
                if (this.active_quiz) {
                    this.title = this.active_quiz.title;
                    this.active_quiz.questions = this.shuffle(this.active_quiz.questions);

                    if (this.active_quiz.random_amount && this.active_quiz.questions.length >= this.active_quiz.random_amount) {
                        this.active_quiz.questions = this.active_quiz.questions.splice(0, this.active_quiz.random_amount);
                    }

                    if (this.quiz_length === 0) {
                        this.quiz_length = this.active_quiz.questions.length;
                    }

                    const tmpCurrent = this.active_quiz.questions.shift();
                    if (tmpCurrent) {
                        this.current_question.id = tmpCurrent.id;
                        this.current_question.title = tmpCurrent.title;
                        this.current_question.explanation = tmpCurrent.explanation;
                        this.current_question.answers = [];
                        tmpCurrent.answers.forEach((answer) => {
                            this.current_question.answers.push({
                                id: answer.id,
                                title: answer.title,
                                selected: false,
                                correct: false,
                            });
                        });

                        this.current_question.answers = this.shuffle(this.current_question.answers);
                    }
                }
            },

            shuffle(a) {
                for (let i = a.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [a[i], a[j]] = [a[j], a[i]];
                }
                return a;
            },

            setCorrectIncorrectValues(remote) {
                this.answered_questions.forEach((question) => {
                    remote.forEach((remoteQuestion) => {
                        if (question.id === remoteQuestion.id) {
                            question.answers.forEach((answer) => {
                                remoteQuestion.answers.forEach((remoteAnswer) => {
                                    if (answer.id === remoteAnswer.id) {
                                        answer.correct = remoteAnswer.correct;
                                    }
                                });
                            });
                        }
                    });
                });
            },

            setPercentageOfCorrect() {
                let totalCorrect = 0;

                this.answered_questions.forEach((question) => {
                    let totalCorrectAnswers = 0;
                    let correctAnswered = 0;
                    let incorrectAnswered = false;

                    question.answers.forEach((answer) => {
                        if (answer.correct) {
                            totalCorrectAnswers++;
                        }

                        if (answer.correct && answer.selected) {
                            correctAnswered++;
                        }

                        if (!answer.correct && answer.selected) {
                            incorrectAnswered = true;
                        }
                    });

                    if (totalCorrectAnswers === correctAnswered && !incorrectAnswered) {
                        totalCorrect++;
                    }
                });

                this.correctPercentage = Math.round(totalCorrect / this.answered_questions.length * 100);
            },

            getAnswerStyle(answer) {
                if (!answer.correct && answer.selected) {
                    return { color: 'red' };
                } else if (answer.correct && answer.selected) {
                    return { color: 'green' };
                }

                return {};
            },

            showQuiz() {
                $('.quiz-holder').show();
                $('#vimeo_video_id').hide();
                this.show_quiz = true;
            },

            hideQuiz() {
                $('#vimeo_video_id').show();
                this.show_quiz = false;
                this.resetQuiz();
                this.selectNextTabElement();
            },

            selectNextTabElement() {
                const elements = $('.nav__item-child');
                const nextEl = elements.find('.active').parent().next();
                if (nextEl.length > 0) {
                    nextEl.find('a').trigger('click');
                } else {
                    $(elements[0]).find('a').trigger('click');
                }
            },

            checkAtLeastOneSelected() {
                return this.current_question.answers.find(obj => obj.selected === true);
            },

            setRelatedQuizzesAsPassed(id) {
                $(`.quiz_id-${id}`).each((index, item) => {
                    $(item).addClass('passed');
                });
            },

            setRelatedQuizzesAsTaken(id) {
                $(`.quiz_id-${id}`).each((index, item) => {
                    $(item).addClass('failed');
                });
            },

            addUniquePassedData(data) {
                const existingQuizData = this.passedQuizzesData.find(obj => obj.id === data.id);
                if (existingQuizData) {
                    existingQuizData.score = data.score;
                    existingQuizData.updated_at = data.updated_at;
                } else {
                    this.passedQuizzesData.push(data);
                }
            },

            showExplanation(question) {
                return question.answers.find(obj => (obj.selected && !obj.correct) || (obj.correct && !obj.selected));
            },
        },
    };
</script>
