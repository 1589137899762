var render, staticRenderFns
import script from "./EventRegistration.vue?vue&type=script&lang=js&"
export * from "./EventRegistration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Laragon\\custom-clearance\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('4d5215cc', component.options)
    } else {
      api.reload('4d5215cc', component.options)
    }
    
  }
}
component.options.__file = "resources/assets/js/components/EventRegistration.vue"
export default component.exports