require('./bootstrap');
import Splide from '@splidejs/splide';
import tippy from 'tippy.js';
import Toasted from 'vue-toasted';
import { getCookie, setCookie } from './utils/functions';
import Store from './utils/store';

function vimeoPlay() {
    const iframe = $('#vimeo_video_id iframe');
    if (iframe.length) {
        const player = new Vimeo.Player(iframe[0]);
        $('#play_vimeo_button').css('display', 'none');
        $('.preview-course-overlay').css('display', 'none');
        $('.preview-course-text').css('display', 'none');
        player.play();
    }
}

function initVimeoPlay() {
    $('#play_vimeo_button').click((e) => {
        e.preventDefault();
        vimeoPlay();
    });
}

function setBannerHeight(height = null) {
    const startLearning = $('.new__start-learning.with-filters');
    const activeFiltersBlock = $('.active_filters:not(.hidden)');
    const newInnerFilter = $('.new__inner-filter:not(.journal)');

    if (newInnerFilter) {
        newInnerFilter.css({ marginBottom: activeFiltersBlock.innerHeight() + 30 });
        $(window).resize(() => {
            newInnerFilter.css({ marginBottom: activeFiltersBlock.innerHeight() + 30 });
        });
    }

    if (startLearning && activeFiltersBlock && !height) {
        startLearning.css({ marginBottom: activeFiltersBlock.innerHeight() + 45 });
        $(window).resize(() => {
            startLearning.css({ marginBottom: activeFiltersBlock.innerHeight() + 45 });
        });
    }

    // eslint-disable-next-line eqeqeq
    if (height !== null) {
        startLearning.css({ marginBottom: height });
        if (newInnerFilter) {
            newInnerFilter.css({ marginBottom: height });
        }
    }
}

function resizeCourseNav() {
    const courseList = $('.additional-course-info-block-info .course__nav');
    const video = $('#vimeo_video_id');

    if (courseList && video) {
        courseList.css({ maxHeight: video.outerHeight() });
    }
}

function loadSlider(entries) {
    entries.forEach((entry) => {
        const slideCount = entry.querySelectorAll('.splide__slide').length;

        if (entry.classList.contains('testimonial-slide')) {
            new Splide(entry, {
                autoplay: true,
                arrows: false,
                pagination: false,
                rewind: true,
                perPage: 3,
                gap: '16px',
                type: slideCount > 3 ? 'loop' : 'slide',
                padding: {
                    right: slideCount > 3 ? '10%' : 0,
                },
                breakpoints: {
                    1550: {
                        perPage: 2,
                        pagination: false,
                    },
                    991: {
                        perPage: 1,
                        pagination: true,
                    },
                },
            }).mount();
        } else {
            new Splide(entry, {
                autoplay: true,
                arrows: slideCount > 1,
                pagination: slideCount > 1,
                rewind: true,
            }).mount();
        }
    });
}

function bindLogic() {
    $('.post__title, .courses-list__item-text h5').each(function () {
        // noinspection InnerHTMLJS
        this.innerHTML = this.innerHTML.replace(/®/g, '<sup>®</sup>');
    });

    $(':input').inputmask();

    // disable active lang link
    $('.lang .active a').click((e) => {
        e.preventDefault();
    });

    const useFunctionalCookies = getCookie('functional_cookies') && getCookie('functional_cookies') === '1';

    // show infoline
    if (!getCookie('infoline') || !useFunctionalCookies) {
        $('.infoline').fadeIn();
    }

    // close infoline
    $('.infoline .close-x').click(function (e) {
        e.preventDefault();
        $(this).parent('.infoline').fadeOut();
        if (useFunctionalCookies) {
            setCookie('infoline', '1', 1);
        }
    });

    if (!getCookie('cc_subscribe_bar_closed') || !useFunctionalCookies) {
        const $el = $('#news-letter-bar');
        $el.fadeIn();
        $el.find('.newsletter__close').click((e) => {
            e.preventDefault();
            $el.fadeOut();
            if (useFunctionalCookies) {
                setCookie('cc_subscribe_bar_closed', '1', 1);
            }
        });
    }

    // show/hide mobile menu
    $('header .mobile-menu, header .mobile-expand a').click((e) => {
        e.preventDefault();
        const header = $('header');
        const doc = $('html, body');
        header.toggleClass('active');
        doc.css({
            overflow: 'auto',
            height: 'auto',
        });
        if (header.hasClass('active')) {
            doc.css({
                overflow: 'hidden',
                height: '100%',
            });
        }
    });

    // show/hide menu block on mobile
    $('.side-menu h3').click((e) => {
        e.preventDefault();
        $('.side-menu ul').slideToggle();
    });

    $(document).scroll(() => {
        // sticky header
        if ($(document).scrollTop() > 200) {
            $('body').addClass('fixed');
        } else {
            $('body').removeClass('fixed');
        }
    });

    $('.datepicker').datepicker({
        format: 'yy-mm-dd',
    });

    tippy('.tippy', {
        theme: 'light',
        arrow: true,
        animation: 'fade',
    });

    $('.faq__title').click(function () {
        const $el = $(this).parent();

        $el.toggleClass('opened');
        $el.find('.faq__content').slideToggle();
    });

    // Quick and dirty
    const pathname = window.location.pathname;
    $('a').each(function () {
        const track = $(this).attr('data-url-track');
        if (track === 'no') {
            return;
        }

        let href = $(this).attr('href');
        if (!href) {
            return;
        }

        if (href.indexOf('http') === 0) {
            if (href.indexOf(window.location.host) >= 0) {
                href = href.substr(href.indexOf(window.location.host) + window.location.host.length);
            } else {
                return;
            }
        }

        if (href.endsWith(pathname)) {
            $(this).addClass('url-current');
        } else if (href.indexOf(pathname) >= 0) {
            $(this).addClass('url-partial');
        }
    });

    // scroll to content
    if ($('body').width() < 992) {
        if (window.location.href.indexOf('date_from') > -1) {
            if ($('#filter-results-content').length > 0) {
                $('html, body').animate({
                    scrollTop: $('#filter-results-content').offset().top - 80,
                }, 250);
            }
        }
    }

    document.querySelectorAll('[js-search-form]').forEach((el) => {
        el.addEventListener('submit', (event) => {
            const queryInput = el.querySelector('[name="q"]');

            if (!queryInput) {
                return;
            }

            if (queryInput.value.length < 3) {
                event.preventDefault();
                tippy(queryInput).show();
            }
        });
    });

    $('#start-learning-header-form').submit((e) => {
        const val = $('#start-learning-header-form :input[name=\'q\']').val();
        if (val.length > 0 && val.length < 3) {
            e.preventDefault();

            const btn = $(document.activeElement)[0];
            const instance = tippy(btn);
            if (instance) {
                instance.show();
            }
        }
    });

    let settingSeen = false;
    // courses video navigation
    $('.course__nav .course__nav-item a').click(async (e) => {
        e.preventDefault();

        if ($(e.target).hasClass('active')) {
            vimeoPlay();
            return;
        }

        if (settingSeen) {
            return;
        }
        settingSeen = true;

        let thisId = e.target.hash;
        const thisTitle = e.target.title;
        thisId = `https://player.vimeo.com/video/${thisId.replace('#', '')}`;
        $('.course__video iframe').attr('src', thisId);
        $('.course__video iframe').attr('title', thisTitle);
        $('.course__nav .course__nav-item a').removeClass('active');
        $('.quiz-holder').hide();
        $('#vimeo_video_id').show();
        $('.course__nav-video-quiz a').removeClass(['active-quiz', 'active']);
        e.target.classList.add('active');

        try {
            const thisTranslationID = $(e.target).find('.video_translation_id').html();
            const data = await axios.post('/courses/set-video-seen', { videoTranslationId: thisTranslationID });
            if (data.data.data.set_success) {
                e.target.classList.add('watched');
            }
        } catch (err) {
            console.error(err);
        }

        settingSeen = false;
    });

    $('.course__nav-video-quiz').click((e) => {
        e.preventDefault();
        const thisId = e.target.hash.replace('#', '');
        $('.course__nav-video-quiz a').removeClass(['active-quiz', 'active']);
        $('.course__nav .course__nav-item a').removeClass('active');
        e.target.classList.add('active-quiz', 'active');
        document.dispatchEvent(new CustomEvent('course-quiz', { detail: { id: thisId } }));
    });

    // courses video navigation
    $('.course__tabs a').click((e) => {
        e.preventDefault();
        $('.course__tabs a').removeClass('active');
        $('.course__tabs-info').removeClass('course__tabs-info--active');
        $(e.target.hash).addClass('course__tabs-info--active');
        e.target.classList.add('active');

        loadSlider(Array.from(document.querySelectorAll('.course__reviews .splide')));
    });

    $('[check-all]').each(function () {
        const item = $(this);
        const checkBoxes = $(`[name='${item.attr('check-all')}']`);

        function generateButtonTitle() {
            if (checkBoxes.not(':checked').length >= 1) {
                item.text(item.attr('select-text'));
            } else {
                item.text(item.attr('unselect-text'));
            }
        }

        generateButtonTitle();

        checkBoxes.change(() => {
            generateButtonTitle();
        });

        item.click(e => {
            e.preventDefault();
            if (checkBoxes.not(':checked').length >= 1) {
                checkBoxes.prop('checked', true);
            } else {
                checkBoxes.prop('checked', false);
            }
            generateButtonTitle();
        });
    });

    $('.collapsable-content').each(function () {
        const $el = $(this);
        const $content = $el.find('.collapsable-content__body');
        const $showMore = $el.find('.collapsable-content__show-more');
        const $showLess = $el.find('.collapsable-content__show-less');
        if ($content.height() > $el.height()) {
            $el.find('.collapsable-content__footer').show();
            $el.addClass('collapsable-content--expandable');
            if ($el.hasClass('active')) {
                $showLess.show();
            } else {
                $showMore.show();
            }
        }

        $showMore.find('a').click(e => {
            e.preventDefault();
            $showMore.hide();
            $showLess.show();
            $el.toggleClass('active');
        });

        $showLess.find('a').click(e => {
            e.preventDefault();
            $showMore.show();
            $showLess.hide();
            $el.toggleClass('active');
        });
    });

    loadSlider(Array.from(document.querySelectorAll('.splide')));
}

const $filterCheckboxes = $('.filter .checkboxes.checkboxes--collapsed');
if ($filterCheckboxes.length) {
    const calculateItemsHeight = ($items, count) => {
        let height = 0;
        for (let i = 0; i < count; i++) {
            height += $items.eq(i).outerHeight(true);
        }
        if ($('body').width() < 992) {
            // hack for mobile
            if (height > 130) {
                height += 5;
            } else {
                height = 150;
            }
        }
        return height;
    };

    $(document).on('click', '.filter-show-more', (e) => {
        e.preventDefault();
        const $btn = $(e.currentTarget);
        const $el = $btn.parent().find('.checkboxes');
        const $items = $el.children();
        if ($btn.hasClass('active')) {
            $el.animate({
                height: calculateItemsHeight($items, 5),
            }, () => {
                $btn.removeClass('active');
                $btn.find('.filter-show-less__text').hide();
                $btn.find('.filter-show-more__text').show();
            });
        } else {
            $el.animate({
                height: $el.get(0).scrollHeight,
            }, () => {
                $btn.addClass('active');
                $btn.find('.filter-show-less__text').show();
                $btn.find('.filter-show-more__text').hide();
            });
        }
    });

    $filterCheckboxes.each(function () {
        const $list = $(this);
        const $items = $list.children();
        if ($items.length > 5) {
            $list.css({ height: calculateItemsHeight($items, 5) });
            $list.parent().find('.filter-show-more').show();
            $list.parent().find('.filter-show-more .filter-show-more__text').show();
        }
    });
}


function bindModals() {
    $('[open-modal]').each(function () {
        const $el = $(this);
        const eventName = `modal-open:${$el.attr('open-modal')}`;
        let eventData = $el.data('modal');
        eventData = eventData || {};
        const event = new CustomEvent(eventName, { detail: eventData });

        $el.click((e) => {
            e.preventDefault();
            document.dispatchEvent(event);
        });
    });

    if (window.location.hash) {
        const hash = window.location.hash.substring(1);
        if (hash.search('modal-open=') > -1) {
            const eventName = `modal-open:${hash.replace('modal-open=', '')}`;
            const event = new CustomEvent(eventName);
            document.dispatchEvent(event);
        }
    }

    const authenticatedEvent = () => {
        window.location.reload();
    };

    $('[open-auth-modal]').click((e) => {
        e.preventDefault();
        document.removeEventListener('authenticated', authenticatedEvent);
        document.dispatchEvent(new Event('modal-open:auth-modal'));
        document.addEventListener('authenticated', authenticatedEvent);
    });
}

function videoModal() {
    $('#playVideo').on('hidden.bs.modal', () => {
        $('#playVideo #modalVideo').attr('src', $('#playVideo #modalVideo').attr('src'));
    });
}

function togglePanelItems() {
    const btns = document.getElementsByClassName('toggle-expand');
    for (let i = 0; i < btns.length; i++) {
        btns[i].addEventListener('click', (e) => {
            e.preventDefault();
            btns[i].classList.toggle('open');
            const parentElement = btns[i].parentElement;
            const subtractedElements = parentElement.querySelectorAll('.subtract-on-mobile');
            const expandedElements = parentElement.querySelectorAll('.expanded');

            for (let k = 0; k < expandedElements.length; k++) {
                expandedElements[k].classList.replace('expanded', 'subtract-on-mobile');
            }

            for (let j = 0; j < subtractedElements.length; j++) {
                subtractedElements[j].classList.replace('subtract-on-mobile', 'expanded');
            }
        });
    }
}

function bindCopyToClipboard() {
    $('.copy-to-clipboard').each(function () {
        const $el = $(this);
        $el.click((e) => {
            e.preventDefault();
            const $temp = $('<input>');
            $('body').append($temp);
            $temp.val($el.attr('href')).select();
            document.execCommand('copy');
            $temp.remove();
        });
    });
}

function checkResultsCount() {
    const counter = $('#resultCounter');
    if (counter.data('count') === 0) {
        $('.wrapper').css({ overflow: 'auto' });
    }
}

function scrollOnSearch() {
    window.addEventListener('load', () => {
        const activeFilterBar = $('.active_filters:not(.hidden)');
        if ((window.location.href.indexOf('search?') > -1 || window.location.href.indexOf('topic=') > -1) && activeFilterBar.length > 0) {
            $('html, body').animate({
                scrollTop: activeFilterBar.offset().top - 70,
            }, 350);
        }
    });
}

function bindLimitVisible() {
    $('[data-limit-visible]').each(function () {
        const $el = $(this);
        const limit = $el.attr('data-limit-visible');
        const $body = $el.find('.limit-visible__body').first();
        let visible = false;
        const $showButton = $el.find('.limit-visible__show-more');
        const $hideButton = $el.find('.limit-visible__show-less');
        const $elements = $body.children();
        if ($elements.length <= limit) {
            $hideButton.hide();
            $showButton.hide();
            return;
        }

        const toggleVisibility = function () {
            if (visible) {
                $hideButton.show();
                $showButton.hide();
            } else {
                $hideButton.hide();
                $showButton.show();
            }

            $elements.each(function (index) {
                const $child = $(this);
                if (index + 1 > limit) {
                    if (visible) {
                        $child.show();
                    } else {
                        $child.hide();
                    }
                }
            });
        };

        toggleVisibility();

        $showButton.click(() => {
            visible = true;
            toggleVisibility();
        });

        $hideButton.click(() => {
            visible = false;
            toggleVisibility();
        });
    });
}

function bindFilters() {
    document.querySelectorAll('[js-toggle-filter]').forEach((el) => {
        el.addEventListener('click', (e) => {
            e.preventDefault();

            const target = document.getElementById('content-filter');
            if (target) {
                target.classList.toggle('hidden');
                el.classList.toggle('is-open');
            }
        });
    });

    document.querySelectorAll('[js-close-filter]').forEach((el) => {
        el.addEventListener('click', (e) => {
            e.preventDefault();

            const target = document.getElementById('content-filter');
            if (target) {
                target.classList.add('hidden');
                el.classList.remove('is-open');
            }
        });
    });

    document.querySelectorAll('.content-filter-select').forEach((el) => {
        const control = el.querySelector('.content-filter-select__control');
        const searchInput = control.firstElementChild;
        const options = el.querySelector('.content-filter-select__options');
        const optionsList = options.querySelectorAll('.content-filter-select-option');
        const visibilityEvent = (e) => {
            if (!el.contains(e.target)) {
                options.classList.add('hidden');
                document.removeEventListener('click', visibilityEvent);
            }
        };
        const filter = () => {
            optionsList.forEach((option) => {
                if (!searchInput.value || option.textContent.toLowerCase().includes(searchInput.value.toLowerCase())) {
                    option.closest('li').classList.remove('hidden');
                } else {
                    option.closest('li').classList.add('hidden');
                }
            });
        };

        searchInput.addEventListener('focus', () => {
            options.classList.remove('hidden');
            document.addEventListener('click', visibilityEvent);
        });

        searchInput.addEventListener('input', filter);

        options.addEventListener('click', (e) => {
            const option = e.target.closest('.content-filter-select-option');

            if (!option) {
                return;
            }

            const input = option.querySelector('input');
            if (!input) {
                return;
            }

            if (input.type === 'radio') {
                input.checked = true;

                if (option.dataset.text) {
                    searchInput.value = option.dataset.text;
                } else {
                    searchInput.value = option.textContent;
                }

                options.classList.add('hidden');
                document.removeEventListener('click', visibilityEvent);
            } else {
                input.checked = !input.checked;
            }
        });
    });
}

Vue.directive('focus', require('./directives/focus').default);
Vue.directive('mount', require('./directives/mount').default);
Vue.directive('inserted', require('./directives/inserted').default);
Vue.directive('removed', require('./directives/removed').default);
Vue.directive('dropdown', require('./directives/dropdown').default);
Vue.directive('scroll-end', require('./directives/scroll-end').default);

Vue.component('v-form', require('./form/Form').default);
Vue.component('v-form-group', require('./form/FormGroup').default);
Vue.component('v-checkbox', require('./form/FormGroup').default);
Vue.component('v-form-error', require('./form/FormError').default);
Vue.component('cc-instructor-form', require('./components/InstructorForm').default);
Vue.component('cc-mailerlite-form', require('./components/MailerLiteForm').default);
Vue.component('cc-auth-form', require('./components/AuthForm').default);
Vue.component('cc-change-password-form', require('./components/profile/ChangePasswordForm').default);
Vue.component('cc-cart-form', require('./components/CartForm').default);
Vue.component('cc-modal', require('./components/Modal').default);
Vue.component('cc-checkout-form', require('./components/CheckoutForm').default);
Vue.component('cc-user-info', require('./components/profile/UserInfo').default);
Vue.component('cc-button', require('./form/Button').default);
Vue.component('cc-preferences-form', require('./components/profile/PreferencesForm').default);
Vue.component('cc-report-form', require('./components/ReportForm').default);
Vue.component('cc-pdf', require('./components/Pdf').default);
Vue.component('cc-notification-indicator', require('./components/NotificationIndicator').default);
Vue.component('cc-tippy', require('./components/Tippy').default);
Vue.component('cc-delete-account-form', require('./components/DeleteAccountForm').default);
Vue.component('cc-select', require('./form/Select').default);
Vue.component('cc-dropdown', require('./form/dropdown/Dropdown').default);
Vue.component('cc-unsubscribe-form', require('./components/newsletter-subscribtions/UnsubscribeForm').default);
Vue.component('cc-unlock-form', require('./components/UnlockForm').default);
Vue.component('cc-login-or-register', require('./components/LoginOrRegister').default);
Vue.component('cc-order-summary', require('./components/OrderSummary').default);
Vue.component('cc-quiz-form', require('./components/QuizForm').default);
Vue.component('cc-comments', require('./components/Comments').default);
Vue.component('cc-comment', require('./components/Comment').default);
Vue.component('cc-invitation-form', require('./components/profile/business-account/InvitationForm').default);
Vue.component('cc-license-purchase-subscription-form', require('./components/profile/business-account/LicensePurchaseSubscriptionForm').default);
Vue.component('cc-license-purchase-course-form', require('./components/profile/business-account/LicensePurchaseCourseForm').default);
Vue.component('cc-license-grant-access-form', require('./components/profile/business-account/LicenseGrantAccessForm').default);
Vue.component('cc-contact-us-form', require('./components/ContactUsForm').default);
Vue.component('cc-register-event', require('./components/EventRegistration').default);

Vue.filter('currency', require('./filters/currency').default);

Vue.use(Toasted);

new Vue({
    store: Store,
    mounted() {
        bindLogic();
        bindModals();
        bindCopyToClipboard();
        bindLimitVisible();
        initVimeoPlay();
        setBannerHeight();
        togglePanelItems();
        videoModal();
        checkResultsCount();
        scrollOnSearch();
        resizeCourseNav();
        bindFilters();
        Store.commit('getCart');
    },
}).$mount('#app');

$(document).ready(() => {
    require('./cookie-consent');
});

$(window).resize(() => {
    if ($(window).width() >= 991) {
        setTimeout(resizeCourseNav, 50);
    }
});
