import isString from 'lodash/isString';
import pick from 'lodash/pick';

export default class FormErrors {
    /**
     * FormErrors constructor.
     */
    constructor() {
        this.errors = {};
    }

    /**
     * Determine if field has error.
     * @param {String} name
     * @returns {Boolean}
     */
    has(name) {
        return this.errors.errors && this.errors.errors[name];
    }

    /**
     * Get field errors.
     * @param {String} name
     * @returns {Array|null}
     */
    get(name) {
        if (!this.errors || !this.errors.errors) {
            return null;
        }

        return this.errors.errors[name];
    }

    set(name, value) {
        this.setErrors({ errors: Object.assign({}, this.errors.errors, { [name]: value }) });
    }

    remove(name) {
        const errors = this.errors.errors;
        if (!errors) {
            return;
        }

        delete errors[name];
        this.setErrors({ errors: Object.assign({}, this.errors.errors) });
    }

    /**
     * Count how many errors exist using regexp.
     * @param {RegExp} regexp
     * @returns {Number}
     */
    errorsCountExp(regexp) {
        if (!this.errors || !this.errors.errors) {
            return 0;
        }

        const expression = isString(regexp) ? new RegExp(regexp) : regexp;
        return Object.keys(this.errors.errors)
            .filter(field => field.match(expression) !== null).length;
    }

    /**
     * Set errors.
     * @param {*} errors
     */
    setErrors(errors) {
        this.errors = errors;
    }

    /**
     * Clear errors.
     */
    clear(except) {
        if (!except || typeof except !== 'object') {
            this.errors = {};
        }

        if (!this.errors.errors) {
            return;
        }

        this.setErrors({ errors: pick(this.errors.errors, except) });
    }

    /**
     * Update errors from response.
     * @param {AxiosResponse} response
     * @param keepErrors
     * @returns {*}
     */
    updateFromResponse(response, keepErrors) {
        if (isString(response) || !response.response) {
            return response;
        }

        if (typeof response.response.data === 'object' && typeof response.response.data.error === 'object') {
            const errors = response.response.data.error;
            if (keepErrors && typeof keepErrors === 'object' && this.errors.errors) {
                errors.errors = { ...pick(this.errors.errors, keepErrors), ...errors.errors };
            }
            this.setErrors(response.response.data.error);
        }

        return response;
    }
}
