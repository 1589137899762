<script>
import { FormModel } from '../form';
import Stripe from './payment-methods/Stripe';

const TYPE_CARD = 'stripe';
const TYPE_BANK = 'bank';

const manualErrorColumns = ['card'];

export default {
    name: 'checkoutForm',

    components: { 'cc-stripe': Stripe },

    data() {
        return {
            submitting: false,
            completed: false,
            model: new FormModel({
                name: '',
                organization_id: null,
                country_id: null,
                payment_method: TYPE_CARD,
                as_organization: false,
            }),
            client_secret: '',
            order_id: null,
            paymentTypes: {
                card: TYPE_CARD,
                bank: TYPE_BANK,
            },
        };
    },

    props: {
        successPageUrl: {
            required: true,
            type: String,
        },

        hasOrganizations: {
            default: false,
            type: Boolean,
        },
    },

    created() {
        if (this.hasOrganizations) {
            this.model.$.as_organization = true;
        }
    },

    watch: {
        'model.$.as_organization'(value) {
            if (!value) {
                this.model.$.organization_id = null;
            }
        },
    },

    methods: {
        async submit() {
            if (this.submitting) {
                return;
            }

            this.submitting = true;
            this.model.$e.clear();

            try {
                const response = await axios.post('/api/checkout/place-order', this.model.$);
                this.client_secret = response.data.data.client_secret;
                this.order_id = response.data.data.order_id;

                if (this.model.$.payment_method === this.paymentTypes.bank) {
                    this.redirectToSuccessPage(response.data.data.order_id);
                }
            } catch (e) {
                this.model.$e.updateFromResponse(e, manualErrorColumns);
                this.submitting = false;
            }
        },

        handlePayment(e) {
            if (e.success) {
                this.redirectToSuccessPage(this.order_id);
            }

            this.submitting = false;
        },

        redirectToSuccessPage(orderId) {
            window.location = `${this.successPageUrl}/${orderId}`;
        },

        setBankPayment() {
            this.model.$.payment_method = TYPE_BANK;
        },

        setCardPayment() {
            this.model.$.payment_method = TYPE_CARD;
        },
    },
};
</script>
