<script>
import Model from '../../../form/FormModel';

export default {
    name: 'cc-license-purchase-subscription-form',

    props: {
        cartUrl: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            model: new Model({
                subscription_id: '',
                amount: null,
            }),
            submitting: false,
        };
    },

    methods: {
        async submit(url) {
            if (this.submitting) {
                return;
            }

            this.submitting = true;
            this.success_message = null;
            this.model.$e.clear();

            try {
                await axios.post(url, this.model.$);
                this.model.clear();
                window.location.href = this.cartUrl;
            } catch (e) {
                this.model.$e.updateFromResponse(e);
                this.submitting = false;
            }
        },
    },
};
</script>
