import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);


const store = new Vuex.Store({
    state: {
        cart: {
            items: [],
            loading: true,
            discount: {
                code: '',
                amount: 0,
            },
            cart_identifier: '',
        },
        comments: {
            replyToCommentId: null,
        },
    },
    mutations: {
        replyToComment(state, id) {
            state.comments.replyToCommentId = id;
        },

        clearReplyToComment(state) {
            state.comments.replyToCommentId = null;
        },

        getCart(state) {
            state.cart.loading = true;
            axios.get(`/api/cart/get-cart?lang=${document.documentElement.lang}`)
                .then(res => {
                    state.cart = res.data.data;
                })
                .finally(() => {
                    state.cart.loading = false;
                });
        },

        showCartMessage(state, { vm, template }) {
            const el = $(`[toast-template="${template}"]`);
            if (el.length) {
                const template = el.html();
                vm.$toasted.show(template, {
                    iconPack: 'custom-class',
                    position: 'top-right',
                    type: 'info',
                    duration: 3000,
                    containerClass: 'toast__container',
                    icon: {
                        name: 'btn--cart',
                    },
                });
            }
        },

        setCart(state, cart) {
            state.cart = cart;
        },

        setCartLoading(state, loading) {
            state.loading = loading;
        },

        applyDiscount(state, discount) {
            state.cart.discount = discount;
        },
    },
});

export default store;
