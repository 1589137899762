<script>
    import Popper from 'popper.js';
    import Dropdown from '../../services/dropdown/Dropdown';
    import Popup from '../../services/popup/Popup';
    import { elementWidth } from '../../utils/DOM';

    export default {
        name: 'dropdown',

        props: {
            name: {
                type: String,
                required: true,
            },
            placement: {
                type: String,
                default: 'bottom-start',
            },
            width: {
                default: 'auto',
                validator(value) {
                    return ['auto', 'parent'].includes(value);
                },
            },
            usePopper: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                opened: false,
                popper: null,
                triggerElement: null,
                isVisible: false,
            };
        },

        render(h) {
            if (!this.opened) {
                return h('transition', { props: { name: 'fade' } }, [this._e()]);
            }

            if (!this.$slots.default || this.$slots.default.filter(item => Boolean(item.tag)).length !== 1) {
                return h('transition', { props: { name: 'fade' } }, [this._e()]);
            }

            const node = this.$slots.default[0];
            const data = Object.assign({
                directives: [],
                style: {},
            }, node.data || {});

            data.style.display = this.isVisible ? 'block' : 'none';

            if (this.triggerElement) {
                this.$nextTick(() => {
                    this.openDropdown();
                });
            }

            if (node.componentOptions) {
                return h('transition', { props: { name: 'fade' } }, [node]);
            }

            return h('transition', { props: { name: 'fade' } }, [h(node.tag, data, node.children)]);
        },

        created() {
            Dropdown.add(this.name, this);
        },

        beforeDestroy() {
            if (this.opened) {
                Popup.closed(this);
            }
            this.detachPopper();
            Dropdown.remove(this.name);
        },

        methods: {
            close() {
                this.opened = false;
                this.isVisible = false;
                Popup.closed(this);
                this.$emit('close');
            },

            open(trigger) {
                this.triggerElement = trigger;

                if (!this.opened) {
                    this.opened = true;
                    this.$emit('open');
                }
            },

            toggle(trigger) {
                if (this.opened) {
                    this.close();
                } else {
                    this.open(trigger);
                }
            },

            updatePopper() {
                // Popper has been initiated but turned off
                if (!this.usePopper && this.popper) {
                    this.detachPopper();
                    return;
                }

                // Popper has been turned off
                if (!this.usePopper) {
                    return;
                }

                this.popper = new Popper(this.triggerElement, this.$el, {
                    placement: this.placement,
                    modifiers: {
                        preventOverflow: {
                            boundariesElement: 'window',
                        },
                        computeStyle: {
                            gpuAcceleration: !(window.devicePixelRatio < 1.5 && /Win/.test(navigator.platform)),
                        },
                    },
                });
            },

            detachPopper() {
                if (this.popper) {
                    this.popper.destroy();
                    this.popper = null;
                }
            },

            openDropdown() {
                if (!this.triggerElement) {
                    return;
                }

                this.isVisible = true;

                this.updatePopper();

                Popup.opened(this, () => {
                    this.close();
                });

                // Next tick is required to prevent including sidebar in width calculation
                this.$nextTick(() => {
                    if (this.width === 'parent') {
                        this.$el.style.width = `${elementWidth(this.triggerElement)}px`;
                    }
                });
            },
        },
    };
</script>
