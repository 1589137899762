var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "multi-select",
      class: { "multi-select--multiple": _vm.multiple },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.openDropdown($event)
        }
      }
    },
    [
      _c("div", { staticClass: "multi-select__content" }, [
        _vm.placeholderVisible
          ? _c("div", { staticClass: "multi-select__placeholder" }, [
              _vm._v(_vm._s(_vm.placeholder))
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.multiple && _vm.internalValue && !_vm.inputVisible
          ? _c(
              "div",
              { staticClass: "multi-select__selection" },
              [
                _vm._t(
                  "single",
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.internalValue.text) +
                        "\n            "
                    )
                  ],
                  {
                    keyValue: _vm.internalValue.key,
                    item: _vm.internalValue.item
                  }
                )
              ],
              2
            )
          : _vm.multiple && _vm.internalValue
          ? _c(
              "div",
              { staticClass: "multi-select__selection" },
              _vm._l(_vm.internalValue, function(item) {
                return _c(
                  "div",
                  { staticClass: "multi-select-tag" },
                  [
                    _vm._t("tag", [_c("span", [_vm._v(_vm._s(item.text))])], {
                      keyValue: item.key,
                      item: item.item
                    }),
                    _vm._v(" "),
                    !_vm.readonly
                      ? _c(
                          "div",
                          {
                            staticClass: "multi-select-tag__button",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.removeItem(item)
                              }
                            }
                          },
                          [_vm._v("×")]
                        )
                      : _vm._e()
                  ],
                  2
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.inputVisible
          ? _c("input", {
              directives: [
                { name: "focus", rawName: "v-focus" },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.input,
                  expression: "input"
                }
              ],
              staticClass: "multi-select__search",
              attrs: { type: "text" },
              domProps: { value: _vm.input },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.input = $event.target.value
                  },
                  _vm.updateFilter
                ],
                keydown: _vm.keyboardAction
              }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      !_vm.readonly
        ? _c("div", { staticClass: "multi-select__buttons" }, [
            _vm.allowClear && _vm.internalValue
              ? _c(
                  "div",
                  {
                    staticClass: "multi-select__button",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.clearSelection($event)
                      }
                    }
                  },
                  [_vm._v("×")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "multi-select__button multi-select__button--root-hover"
              },
              [
                _c("span", {
                  staticClass: "multi-select__arrow",
                  class: { "multi-select__arrow--flip": _vm.dropdownOpen }
                })
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "cc-dropdown",
        {
          directives: [
            {
              name: "mount",
              rawName: "v-mount",
              value: "body",
              expression: "'body'"
            }
          ],
          attrs: { width: "parent", name: _vm.dropdownKey },
          on: { open: _vm.onDropdownOpen, close: _vm.onDropdownClose }
        },
        [
          _c(
            "div",
            {
              staticClass: "multi-select-dropdown",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.inputBlur($event)
                }
              }
            },
            [
              _vm.createVisible
                ? _c(
                    "div",
                    {
                      staticClass: "multi-select-item",
                      on: { click: _vm.createItem }
                    },
                    [
                      _vm._v("\n                " + _vm._s("Create") + " "),
                      _c("strong", [_vm._v(_vm._s(_vm.input))])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.items || _vm.filteredGroups.length === 0
                ? _c("div", { staticClass: "multi-select-dropdown__message" }, [
                    _vm._v("Empty")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "multi-select-dropdown__results" },
                _vm._l(_vm.filteredGroups, function(group) {
                  return _c(
                    "div",
                    { staticClass: "multi-select-group" },
                    [
                      group.title
                        ? _c(
                            "div",
                            { staticClass: "multi-select-group__title" },
                            [_vm._v(_vm._s(group.title))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(group.items, function(item) {
                        return _c(
                          "div",
                          {
                            staticClass: "multi-select-item",
                            class: {
                              "multi-select-item--selected": _vm.isSelected(
                                item
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.selectItem(item)
                              }
                            }
                          },
                          [
                            _vm._t(
                              "option",
                              [
                                _c("span", {
                                  domProps: { innerHTML: _vm._s(item.text) }
                                })
                              ],
                              { keyValue: item.key, item: item.item }
                            )
                          ],
                          2
                        )
                      })
                    ],
                    2
                  )
                }),
                0
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }