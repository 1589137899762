<template>
    <span>
        <slot ref="button"></slot>
        <div ref="content" v-show="false">
            <slot name="content"></slot>
        </div>
    </span>
</template>

<script>
    import tippy from 'tippy.js';

    export default {
        name: 'cc-tippy',

        mounted() {
            tippy(this.$slots.default[0].elm, {
                theme: 'light',
                arrow: true,
                animation: 'fade',
                content: this.$refs.content.innerHTML,
                interactive: true,
            });
        },
    };
</script>
