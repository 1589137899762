<script>
    import FormModel from './../form/FormModel';
    import _remove from 'lodash/remove';

    export default {
        name: 'Comments',
        props: {
            type: {
                type: String,
                default: 'article',
                validator(value) {
                    return ['article', 'course', 'learning-path'].indexOf(value) >= 0;
                },
            },
            slug: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                comments: [],
                loading: false,
                model: new FormModel({
                    comment: '',
                }),
                replyModel: new FormModel({
                    comment: '',
                }),
                successMessage: false,
                pagination: {
                    total: 0,
                },
                replyToComment: null,
            };
        },

        created() {
            this.load();
        },

        computed: {
            moreExists() {
                return (this.pagination && this.pagination.current_page < this.pagination.last_page) || (this.pagination && this.pagination.current_page === 0 && this.pagination.total > 3);
            },
        },

        methods: {
            replyTo(commentId) {
                this.replyToComment = commentId;
            },

            cancelReply() {
                this.replyToComment = null;
            },

            async load() {
                this.loading = true;
                const response = await axios.get(`/api/comments/${this.type}/${this.slug}`);
                this.comments = response.data.data;
                this.pagination = response.data.meta.pagination;
                this.loading = false;
            },

            async loadMore() {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                const response = await axios.get(`/api/comments/${this.type}/${this.slug}?page=${this.pagination.current_page + 1}`);
                this.comments = [...this.comments, ...response.data.data];
                this.pagination = response.data.meta.pagination;
                this.loading = false;
            },

            async post() {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.model.$e.clear();
                this.successMessage = false;

                try {
                    await axios.post(`/api/comments/${this.type}/${this.slug}`, this.model.$);
                    this.successMessage = true;
                    this.model.reset();
                    await this.load();
                } catch (e) {
                    this.model.$e.updateFromResponse(e);
                }

                this.loading = false;
            },

            async submitReply() {
                if (this.loading) {
                    return;
                }
                this.loading = true;

                this.replyModel.$e.clear();

                try {
                    await axios.post(`/api/comments/${this.type}/${this.slug}/${this.replyToComment}`, this.replyModel.$);
                    this.replyModel.reset();
                    await this.load();
                } catch (e) {
                    this.replyModel.$e.updateFromResponse(e);
                }

                this.loading = false;
            },

            async destroy(commentId) {
                if (this.loading) {
                    return;
                }
                this.loading = true;

                try {
                    await axios.delete(`/api/comments/delete/${commentId}`);
                    const comments = this.comments;
                    _remove(comments, (item) => item.id === commentId);
                    this.comments = comments;
                } catch (e) {
                    this.model.$e.updateFromResponse(e);
                }

                this.loading = false;
            },

            removeComment(commentId) {
                this.comments = this.comments.filter(it => it.id !== commentId);
            },
        },
    };
</script>
