export default {
    name: 'scroll-end',

    inserted(el, binding) {
        el.__scrollend__ = {
            listener: event => {
                if (el.scrollHeight - el.scrollTop <= el.clientHeight) {
                    binding.value(event);
                }
            },
        };

        el.addEventListener('scroll', el.__scrollend__.listener);
    },

    unbind(el) {
        if (el.__scrollend__) {
            el.removeEventListener('scroll', el.__scrollend__.listener);
        }
    },
};
