<script>
import { meta } from '../utils/DOM';
import Model from '../form/FormModel';

export default {
    name: 'authForm',

    inject: { modalData: { default: null } },

    data() {
        return {
            loginForm: new Model({
                email: '',
                password: '',
            }),
            registerForm: new Model({
                email: '',
                password: '',
                password_confirmation: '',
                name: '',
                surname: '',
                phone: '',
                agree: false,
                is_company: false,
                company_name: '',
                company_address: '',
                company_city: '',
                company_code: '',
                vat_code: '',
                newsletter_language: '',
            }),
            forgotForm: new Model({
                email: '',
            }),
            resetForm: new Model({
                token: null,
                email: '',
                password: '',
                password_confirmation: '',
            }),
            password_visible: false,
            remember: false,
            submitting: false,
            submitted: false,
            login: true,
            register: false,
            forgot_password: false,
        };
    },

    computed: {
        activeLang() {
            return meta('lang');
        },
    },

    watch: {
        'modalData.data'() {
            if (this.modalData && this.modalData.data && this.modalData.data.activeTab) {
                if (this.modalData.data.activeTab === 2) {
                    this.registerPage();
                } else {
                    this.loginPage();
                }
            }
        },

        'registerForm.$.newsletter_language'() {
            this.$refs['newsletter-language'].blur();
        },
    },

    methods: {
        togglePasswordVisibility() {
            this.password_visible = !this.password_visible;
        },

        onPhoneKeyDown(event) {
            if (event.key === undefined || ['Backspace', 'Delete'].indexOf(event.key) >= 0) {
                return;
            }

            if (['+'].indexOf(event.key) === -1 && (event.key === ' ' || isNaN(Number(event.key)))) {
                event.preventDefault();
            }
        },

        registerPage() {
            this.login = false;
            this.register = true;
            this.forgot_password = false;
        },

        loginPage() {
            this.login = true;
            this.register = false;
            this.forgot_password = false;
        },

        remindPage() {
            this.login = false;
            this.register = false;
            this.forgot_password = true;
        },

        async submitLogin() {
            if (this.submitting) {
                return;
            }

            this.submitting = true;

            const data = {};
            if (this.remember === true) {
                data.remember = true;
            }
            try {
                await axios.post(`/api/auth/login?lang=${this.activeLang}`, this.loginForm.toFormData(data));
                document.dispatchEvent(new Event('authenticated'));
                window.location = `${window.location}?authenticated=1`;
            } catch (e) {
                console.error(e);
                this.loginForm.$e.updateFromResponse(e);
            }

            this.submitting = false;
        },

        async submitRegister() {
            if (this.submitting) {
                return;
            }

            this.submitting = true;

            try {
                await axios.post(`/api/auth/register?lang=${this.activeLang}`, this.registerForm.toFormData({}));
                document.dispatchEvent(new Event('authenticated'));
                window.location = `${window.location}?authenticated=1`;
            } catch (e) {
                console.error(e);
                this.registerForm.$e.updateFromResponse(e);
            }

            this.submitting = false;
        },

        async submitRemindPassword() {
            if (this.submitting) {
                return;
            }

            this.submitting = true;

            try {
                await axios.post(`/api/password/email?lang=${this.activeLang}`, this.forgotForm.toFormData({}));
                this.submitted = true;
                this.forgotForm.$e.clear();
            } catch (e) {
                console.error(e);
                this.forgotForm.$e.updateFromResponse(e);
            }

            this.submitting = false;
        },

        async submitReset() {
            if (this.submitting) {
                return;
            }

            this.submitting = true;
            try {
                await axios.post(`/api/password/reset?lang=${this.activeLang}`, this.resetForm.toFormData({ token: this.$refs.reset_token.value }));
                this.submitted = true;
                this.resetForm.$e.clear();
            } catch (e) {
                console.error(e);
                this.resetForm.$e.updateFromResponse(e);
            }

            this.submitting = false;
        },
    },
};
</script>
