<template>
    <div :id="modalName" class="modal" ref="modal" v-once>
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <button @click.prevent="hide" aria-label="Close" class="close" type="button">
                    <i class="icon icon--close"></i>
                </button>
                <div class="modal-body">
                    <slot v-bind="this"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'cc-modal',

        props: {
            isVisible: {
                default: false,
            },

            modalName: {
                required: true,
            },
        },

        data() {
            return {
                modal: null,
                visible: false,
                modalData: {},
            };
        },

        watch: {
            isVisible() {
                this.visible = this.isVisible;
            },

            visible() {
                this.toggle();
            },
        },

        created() {
            document.addEventListener(`modal-open:${this.modalName}`, this.show);
            document.addEventListener(`modal-close:${this.modalName}`, this.hide);
            this.visible = this.isVisible;
        },

        destroyed() {
            document.removeEventListener(`modal-open:${this.modalName}`, this.show);
            document.removeEventListener(`modal-close:${this.modalName}`, this.hide);
        },

        provide() {
            const modalData = {};
            Object.defineProperty(modalData, 'data', {
                enumerable: true,
                get: () => this.modalData,
            });

            return { modalData };
        },

        mounted() {
            this.modal = $(this.$refs.modal);
            this.modal.modal({
                show: false,
            });
            this.modal.on('hide.bs.modal', this.hide);
            this.modal.on('show.bs.modal', this.show);
            this.toggle();
        },

        methods: {
            toggle() {
                if (this.visible) {
                    this.modal.modal('show');
                } else {
                    this.modal.modal('hide');
                }
            },

            hide() {
                this.visible = false;
                this.$emit('closed');
            },

            show(e) {
                if (e.detail) {
                    this.modalData = e.detail;
                }
                this.visible = true;
                this.$emit('opened');
            },
        },
    };
</script>
