export default {
    name: 'mount',

    inserted(el, binding) {
        let target;

        if (binding.value) {
            if (binding.value === 'body') {
                target = document.body;
            } else {
                target = document.getElementById(binding.value);
            }
        }

        if (target) {
            target.appendChild(el);
        }
    },

    unbind(el) {
        if (el._transitionClasses && el.__vue__) {
            // Will be removed by transition
            return;
        } else if (!el.parentNode) {
            throw new Error('Parent node not available. Did you add v-mount on a non component element?');
        }

        el.parentNode.removeChild(el);
    },
};
