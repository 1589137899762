<template>
    <div class="multi-select" :class="{ 'multi-select--multiple': multiple }" @click.stop="openDropdown">
        <div class="multi-select__content">
            <!-- placeholder -->
            <div v-if="placeholderVisible" class="multi-select__placeholder">{{ placeholder }}</div>

            <!-- selection -->
            <div class="multi-select__selection" v-if="!multiple && internalValue && !inputVisible">
                <slot name="single" :key-value="internalValue.key" :item="internalValue.item">
                    {{ internalValue.text }}
                </slot>
            </div>
            <div v-else-if="multiple && internalValue" class="multi-select__selection">
                <div class="multi-select-tag" v-for="item in internalValue">
                    <slot name="tag" :key-value="item.key" :item="item.item">
                        <span>{{ item.text }}</span>
                    </slot>

                    <div v-if="!readonly" class="multi-select-tag__button" @click.stop.prevent="removeItem(item)">&times;</div>
                </div>
            </div>

            <!-- input -->
            <input v-if="inputVisible" v-focus v-model="input" @input="updateFilter" @keydown="keyboardAction" class="multi-select__search" type="text">
        </div>

        <div v-if="!readonly" class="multi-select__buttons">
            <!-- clear button -->
            <div v-if="allowClear && internalValue" class="multi-select__button" @click.stop.prevent="clearSelection">&times;</div>

            <!-- arrow -->
            <div class="multi-select__button multi-select__button--root-hover">
                <span class="multi-select__arrow" :class="{ 'multi-select__arrow--flip': dropdownOpen }"></span>
            </div>
        </div>

        <cc-dropdown v-mount="'body'" width="parent" :name="dropdownKey" @open="onDropdownOpen" @close="onDropdownClose">
            <div @click.stop="inputBlur" class="multi-select-dropdown">
                <!-- allow create -->
                <div v-if="createVisible" @click="createItem" class="multi-select-item">
                    {{ 'Create' }} <strong>{{ input }}</strong>
                </div>

                <!-- empty list -->
                <div v-if="!items || filteredGroups.length === 0" class="multi-select-dropdown__message">Empty</div>

                <!-- results -->
                <div class="multi-select-dropdown__results">
                    <div v-for="group in filteredGroups" class="multi-select-group">
                        <!-- group title -->
                        <div v-if="group.title" class="multi-select-group__title">{{ group.title }}</div>

                        <!-- group items -->
                        <div v-for="item in group.items"
                             @click="selectItem(item)"
                             :class="{ 'multi-select-item--selected': isSelected(item) }"
                             class="multi-select-item">
                            <slot name="option" :key-value="item.key" :item="item.item">
                                <span v-html="item.text"></span>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </cc-dropdown>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import Dropdown from '../services/dropdown/Dropdown';
    import { removeArrayItem } from '../utils/Array';
    import { dataIterator, dataLength } from '../utils/Data';
    import { highlight, indexOfCi } from '../utils/String';

    export default {
        props: {
            value: null,
            items: {
                type: [Array, Object],
            },
            placeholder: {
                type: String,
            },
            keyName: {
                type: String,
                default: '$value',
            },
            textName: {
                type: String,
                default: '$value',
            },
            match: {
                type: String,
                default: '$keyName',
            },
            closeOnSelect: {
                type: Boolean,
                default: true,
            },
            grouped: {
                type: Boolean,
                default: false,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            search: {
                type: [Boolean, Function],
                default: true,
            },
            allowClear: {
                type: Boolean,
                default: false,
            },
            allowCreate: {
                type: [Boolean, String],
                default: false,
            },
            create: {
                type: Function,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            beforeFilter: {
                type: Function,
                async default() {
                    //
                },
            },
        },

        data() {
            return {
                internalValue: null,
                dropdownOpen: false,
                inputActive: false,
                input: '',
                filter: '',
            };
        },

        computed: {
            inputVisible() {
                if (this.dropdownOpen && this.input) {
                    return true;
                }

                if (!this.search || !this.dropdownOpen) {
                    return false;
                }

                if (this.multiple) {
                    return true;
                }

                return this.inputActive;
            },

            placeholderVisible() {
                if (!this.dropdownOpen && (!this.internalValue || (Array.isArray(this.internalValue) && !this.internalValue.length))) {
                    return true;
                }

                if (this.dropdownOpen && !this.internalValue && !this.inputVisible && !this.input) {
                    return true;
                }

                return false;
            },

            createVisible() {
                if (this.allowCreate === false || !this.input) {
                    return false;
                }

                if (this.allowCreate === '' || this.allowCreate === true) {
                    return this.filteredGroups.length === 0;
                }

                if (this.allowCreate === 'always') {
                    return true;
                }

                return false;
            },

            dropdownKey() {
                return Dropdown.makeKey();
            },

            groups() {
                const groups = [];

                if (dataLength(this.items)) {
                    if (this.grouped) {
                        this.items.forEach(group => {
                            if (dataLength(group.items)) {
                                groups.push({
                                    title: group.title,
                                    items: this.iterateItemsToList(group.items),
                                });
                            }
                        });
                    } else {
                        groups.push({
                            title: null,
                            items: this.iterateItemsToList(this.items),
                        });
                    }
                }

                return groups;
            },

            filteredGroups() {
                if (!this.filter) {
                    return this.groups;
                }

                const groups = [];
                for (const group of this.groups) {
                    const filtered = [];

                    group.items.forEach(item => {
                        if (indexOfCi(item.text, this.filter) >= 0) {
                            filtered.push({
                                key: item.key,
                                text: highlight(item.text, this.filter),
                                item: item.item,
                            });
                        }
                    });

                    if (filtered.length) {
                        groups.push({
                            title: group.title,
                            items: filtered,
                        });
                    }
                }

                return groups;
            },
        },

        watch: {
            value(value) {
                this.updateInternalValue(value);
            },

            items(after, before) {
                if (before.length <= 0) {
                    this.updateInternalValue(this.value);
                }
            },
        },

        created() {
            this.updateInternalValue(this.value);
        },

        methods: {
            updateFilter: debounce(async function (event) {
                await this.beforeFilter(event.target.value);
                this.filter = event.target.value;
            }, 300),

            updateInternalValue(value) {
                if (value === null || value === undefined) {
                    this.internalValue = null;
                    return;
                }

                if (this.multiple) {
                    if (!Array.isArray(value)) {
                        this.internalValue = null;
                        return;
                    }

                    const internalValue = [];

                    value.forEach(selection => {
                        const result = this.itemByKey(selection);
                        if (result) {
                            internalValue.push(result);
                        } else {
                            internalValue.push({
                                key: selection,
                                text: 'Unkown item',
                                item: selection,
                            });
                        }
                    });

                    this.internalValue = internalValue.length ? internalValue : null;
                } else {
                    this.internalValue = this.itemByKey(value) || {
                        key: value,
                        text: 'Unkown item',
                        item: value,
                    };
                }
            },

            inputBlur() {
                this.inputActive = false;
            },

            selectItem(item) {
                if (this.closeOnSelect) {
                    this.closeDropdown();
                }

                if (this.multiple) {
                    if (this.isSelected(item)) {
                        this.removeItem(item);
                    } else {
                        const value = this.internalValue ? this.internalValue.map(value => value.key) : [];
                        value.push(this.itemKey(item.item, item.key));
                        this.$emit('input', value);
                        this.$emit('select', item);
                    }
                } else {
                    if (this.isSelected(item)) {
                        return;
                    }

                    this.$emit('input', item.key);
                    this.$emit('select', item);
                }
            },

            removeItem(item) {
                if (this.readonly) {
                    return;
                }

                const value = this.internalValue ? this.internalValue.map(value => value.key) : [];

                if (!removeArrayItem(value, item.key)) {
                    return;
                }

                this.$emit('input', value);
                this.$emit('remove', item);
            },

            createItem() {
                if (!this.create) {
                    throw new Error('No `create` function has been provided to create item.');
                }

                const item = this.create(this.input);

                // Allow preventing item creation
                if (item === false) {
                    this.closeDropdown();
                    return;
                }

                if (item === undefined || item === null) {
                    throw new Error('Create function must return the created item.');
                }

                if (!item.hasOwnProperty('key') || !item.hasOwnProperty('value')) {
                    throw new Error('Created item must be an object with `key` and `value` properties.');
                }

                this.resetFilter();
                this.selectItem({
                    key: item.key,
                    text: this.itemText(item.value),
                    item: item.value,
                });
            },

            clearSelection() {
                this.$emit('input', this.multiple ? [] : null);
                this.$emit('clear');
            },

            isSelected(item) {
                if (!this.internalValue) {
                    return false;
                }

                if (this.multiple) {
                    if (!Array.isArray(this.internalValue)) {
                        return false;
                    }

                    return this.internalValue.findIndex(internal => internal.key === item.key) >= 0;
                }

                return this.internalValue.key === item.key;
            },

            itemText(item) {
                if (this.textName === '$value') {
                    return item;
                }

                return item[this.textName] || 'Unknown Item';
            },

            itemKey(item, index) {
                if (this.keyName === '$key') {
                    return index;
                } else if (this.keyName === '$value') {
                    return item;
                }

                return item[this.keyName];
            },

            toggleDropdown() {
                Dropdown.find(this.dropdownKey).toggle(this.$el);
            },

            closeDropdown() {
                Dropdown.find(this.dropdownKey).close();
            },

            openDropdown() {
                if (this.readonly) {
                    return;
                }

                if (this.dropdownOpen) {
                    this.onDropdownOpen();
                } else {
                    Dropdown.find(this.dropdownKey).open(this.$el);
                }
            },

            onDropdownClose() {
                this.dropdownOpen = false;
                this.inputActive = false;
                this.resetFilter();
            },

            onDropdownOpen() {
                this.inputActive = true;
                this.dropdownOpen = true;
            },

            resetFilter() {
                this.filter = '';
                this.input = '';
            },

            iterateItemsToList(items) {
                const list = [];

                for (const [key, value] of dataIterator(items)) {
                    list.push({
                        key: this.itemKey(value, key),
                        text: this.itemText(value),
                        item: value,
                    });
                }

                return list;
            },

            selectionMatch(item) {
                if (this.match === '$keyName') {
                    if (this.keyName === '$value' || this.keyName === '$key') {
                        return item;
                    }

                    return item[this.keyName];
                }

                return item[this.match];
            },

            itemMatch(item) {
                if (this.match === '$keyName') {
                    if (this.keyName === '$value') {
                        return item;
                    } else if (this.keyName === '$key') {
                        return this.itemKey(item, item.key);
                    }

                    return item.item[this.keyName];
                }

                return item.item[this.match];
            },

            itemByKey(itemKey) {
                const groupLength = this.groups.length;

                for (let i = 0; i < groupLength; i++) {
                    const group = this.groups[i];

                    const itemsLength = group.items.length;
                    for (let j = 0; j < itemsLength; j++) {
                        const item = group.items[j];

                        if (item.key === itemKey || this.itemMatch(item) === this.selectionMatch(itemKey)) {
                            return item;
                        }
                    }
                }

                return null;
            },

            keyboardAction(event) {
                if (event.keyCode === 13) {
                    // Enter
                    if (this.createVisible) {
                        this.createItem();
                    }

                    event.preventDefault();
                } else if (event.keyCode === 8) {
                    // Backspace
                    if (!this.multiple || !this.internalValue || event.target.value.length > 0) {
                        return;
                    }

                    const item = this.internalValue[this.internalValue.length - 1];
                    const text = item.text;
                    this.removeItem(item);
                    this.input = text;
                    this.filter = text;
                }
            },
        },
    };
</script>
