import Keys from '../../utils/Keys';

const ID = new Keys('dropdown-');

export class Dropdown {
    /**
     * Handler constructor.
     */
    constructor() {
        this._dropdowns = {};
    }

    /**
     * Make dropdown key.
     * @returns {String|Number}
     */
    makeKey() {
        return ID.nextPositive();
    }

    /**
     * Get named dropdown.
     * @param {String} name
     * @returns {*}
     */
    find(name) {
        return this._dropdowns[name];
    }

    /**
     * Add dropdown component.
     * @param {String} name
     * @param {*} dropdown
     */
    add(name, dropdown) {
        if (this._dropdowns[name]) {
            throw new Error(`Dropdown named "${name}" is already registered.`);
        }

        this._dropdowns[name] = dropdown;
    }

    /**
     * Remove dropdown component.
     * @param {String} name
     */
    remove(name) {
        if (this._dropdowns[name]) {
            delete this._dropdowns[name];
        }
    }
}

export default new Dropdown();
