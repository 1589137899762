<script>
    import Model from '../../form/FormModel';

    export default {
        name: 'userOrganization',

        template: '#cc-user-organization-template',

        props: {
            organization: {
                required: true,
                type: Object,
            },
        },

        data() {
            return {
                model: new Model({
                    id: null,
                    company_name: '',
                    company_address: '',
                    company_code: '',
                    company_city: '',
                    vat_code: '',
                }),
                submitting: false,
                submitted: false,
                read_only: Boolean(this.organization.id),
            };
        },

        created() {
            this.model.setPristine(this.organization);
        },

        methods: {
            toggleReadOnly() {
                this.submitted = false;
                this.read_only = !this.read_only;
            },

            async submit() {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;
                try {
                    const response = await axios.post('/api/user/organization', this.model.toFormData());
                    this.submitted = true;
                    this.read_only = true;

                    if (response.data.data && response.data.data.id) {
                        this.model.$.id = response.data.data.id;
                    }

                    this.model.setPristine(this.model.$);
                } catch (e) {
                    this.model.$e.updateFromResponse(e);
                }

                this.submitting = false;
            },

            cancel() {
                this.read_only = true;
                this.model.restore();
            },
        },
    };
</script>
