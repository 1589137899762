<script>
    import { FormErrors } from '../../form';

    export default {
        name: 'cc-stripe',

        props: {
            stripeKey: {
                type: String,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            clientSecret: {
                type: String,
                required: true,
            },
            locale: {
                type: String,
            },
        },

        data() {
            return {
                stripe: null,
                card: null,
                stripe_token: null,
                errors: new FormErrors(),
            };
        },

        watch: {
            clientSecret() {
                this.submit();
            },
        },

        mounted() {
            // eslint-disable-next-line no-undef, new-cap
            this.stripe = Stripe(this.stripeKey);
            const availableLocales = ['ar', 'da', 'de', 'en', 'es', 'fi', 'fr', 'he', 'it', 'ja', 'lt', 'lv', 'ms', 'nb', 'nl', 'pl', 'pt', 'ru', 'sv', 'zh'];
            let locale = 'auto';
            if (this.locale && availableLocales.indexOf(this.locale)) {
                locale = this.locale;
            }
            const elements = this.stripe.elements({ locale: locale });
            this.card = elements.create('card', {
                disableLink: true,
            });
            this.card.mount('#card-element');

            this.card.addEventListener('change', (event) => {
                if (event.error) {
                    this.errors.set('card', [event.error.message]);
                } else {
                    this.errors.remove('card');
                }
            });
        },

        methods: {
            async submit() {
                const createTokenResult = await this.stripe.createToken(this.card);
                if (createTokenResult.error) {
                    this.errors.set('card', [createTokenResult.error.message]);
                    this.$emit('payment', { success: false });
                    return;
                }

                this.stripe_token = createTokenResult.token.id;

                const handleCarPaymentResult = await this.stripe.handleCardPayment(
                    this.clientSecret,
                    this.card,
                    {
                        payment_method_data: {
                            billing_details: { name: this.name },
                        },
                    },
                );

                if (handleCarPaymentResult.error) {
                    this.errors.set('card', [handleCarPaymentResult.error.message]);
                    this.$emit('payment', { success: false });
                    return;
                }

                this.$emit('payment', { success: true });
            },
        },
    };
</script>
