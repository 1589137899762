import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { cloneData } from '../utils/Data';
import FormErrors from './FormErrors';

export default class FormModel {
    /**
     * FormModel constructor.
     * @param {*} modelBase
     */
    constructor(modelBase) {
        this.base = cloneDeep(modelBase);
        this.original = cloneDeep(modelBase);
        this.$ = cloneDeep(modelBase);
        this.$e = new FormErrors();
        this._dataMorph = false;
    }

    /**
     * Get model data, transformed if transformations applied.
     * Warning: do not bind fields to this getter - it's expensive.
     * @returns {*}
     */
    get data() {
        if (!this._dataMorph) {
            return this.$;
        }

        return cloneData(this.$, this._dataMorph);
    }

    /**
     * Set data transformation.
     * @param {false,*} transform
     * @returns {FormModel}
     */
    dataMorph(transform) {
        this._dataMorph = transform;

        return this;
    }

    /**
     * Set pristine model state.
     * @param {*} data
     */
    setPristine(data) {
        this.$ = Object.assign(cloneDeep(this.base), data);
        this.$e.setErrors({});
        this.original = Object.assign(cloneDeep(this.base), this.$);
    }

    /**
     * Reset model to base data.
     */
    clear() {
        this.$ = cloneDeep(this.base);
    }

    /**
     * Reset model to base data.
     */
    restore() {
        this.$ = cloneDeep(this.original);
        this.$e.setErrors({});
    }

    /**
     * Determine if the model is dirty.
     * @returns {boolean}
     */
    isDirty() {
        return !isEqual(this.$, this.original);
    }

    /**
     * Reset model to base values.
     */
    reset() {
        this.setPristine(this.base);
    }

    /**
     * Convert to form data.
     * @returns {FormData}
     */
    toFormData(attach = false, except = []) {
        const formData = new FormData();

        Object.keys(this.$).forEach(key => {
            if (!except.includes(key)) {
                this._appendToFormData(formData, key, this.$[key]);
            }
        });

        if (attach) {
            Object.keys(attach).forEach(key => {
                this._appendToFormData(formData, key, attach[key]);
            });
        }

        return formData;
    }

    /**
     * @param {FormData} formData
     * @param {string} key
     * @param {*} value
     * @private
     */
    _appendToFormData(formData, key, value) {
        if (value === null || value === undefined) {
            return;
        }

        if (Array.isArray(value)) {
            value.forEach(entry => {
                formData.append(`${key}[]`, entry);
            });
        } else {
            formData.append(key, value);
        }
    }
}
