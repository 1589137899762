<script>
import Model from '../../form/FormModel';

export default {
    name: 'UnsubscribeForm',

    data() {
        return {
            model: new Model({
                unsubscribe_email: '',
                action: null,
            }),
            unsubscribed: false,
            submitting: false,
        };
    },

    methods: {
        async submit() {
            if (this.submitting) {
                return;
            }

            this.submitting = true;
            await axios.post('/api/unsubscribe', this.model.$)
                .then(() => {
                    this.unsubscribed = true;
                })
                .catch(e => {
                    console.error(e);
                    this.model.$e.updateFromResponse(e);
                })
                .finally(() => {
                    this.submitting = false;
                });
        },
    },
};
</script>
