<script>
    import Model from '../form/FormModel';

    export default {
        name: 'InstructorForm',

        data() {
            return {
                instructorForm: new Model({
                    name: '',
                    surname: '',
                    email: '',
                    photo: null,
                    background: '',
                    languages: [],
                    agreement: false,
                }),
                submitting: false,
                submitted: false,
            };
        },

        methods: {
            setInstructorFormPhoto(event) {
                if (event.target.files.length) {
                    this.instructorForm.$.photo = event.target.files[0];
                } else {
                    this.instructorForm.$.photo = null;
                }
            },

            async submitInstructorForm() {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;

                try {
                    await axios.post('/api/register/instructor', this.instructorForm.toFormData({}));
                    this.submitted = true;
                } catch (e) {
                    console.error(e);
                    this.instructorForm.$e.updateFromResponse(e);
                }

                this.submitting = false;
            },
        },
    };
</script>
