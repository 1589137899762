<script>
    export default {
        name: 'cc-order-summary',

        props: {
            countryId: {
                required: false,
            },
            organizationId: {
                required: false,
            },
        },

        data() {
            return {
                cart: null,
                loading: true,
            };
        },

        watch: {
            countryId() {
                this.load();
            },
        },

        created() {
            this.load();
        },

        computed: {
            isCartEmpty() {
                return !this.cart || !this.cart.items.length > 0;
            },
        },

        methods: {
            async load() {
                let url = `/api/cart/get-cart?lang=${document.documentElement.lang}`;
                if (this.countryId) {
                    url += `&country_id=${this.countryId}`;
                }
                if (this.organizationId) {
                    url += `&organization_id=${this.organizationId}`;
                }
                const cart = await axios.get(url);
                this.cart = cart.data.data;
                if (this.isCartEmpty) {
                    window.location.reload();
                } else {
                    this.loading = false;
                }
            },

            formatPrice(price, inCents = true) {
                let calculatedPrice = price;
                if (inCents) {
                    calculatedPrice /= 100;
                }
                return calculatedPrice.toFixed(2);
            },
        },
    };
</script>
