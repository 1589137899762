export default class Keys {
    /**
     * Keys constructor.
     * @param {String|null} prefix
     */
    constructor(prefix = null) {
        this._positive = 1;
        this._negative = -1;
        this._prefix = prefix;
    }

    /**
     * Get next positive key.
     * @returns {String|Number}
     */
    nextNegative() {
        return this._prefixedValue(this._negative--);
    }

    /**
     * Get next positive key.
     * @returns {String|Number}
     */
    nextPositive() {
        return this._prefixedValue(this._positive++);
    }

    /**
     * Reset internal keys.
     */
    reset() {
        this._positive = 1;
        this._negative = -1;
    }

    /**
     * Get prefixes value.
     * @param {String|Number} value
     * @returns {String|Number}
     * @private
     */
    _prefixedValue(value) {
        if (this._prefix) {
            return this._prefix + value;
        }

        return value;
    }
}
