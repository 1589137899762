var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _vm.showLabel
        ? _c(
            "label",
            { staticClass: "control-label", class: _vm.labelClasses },
            [
              _vm.required
                ? _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
                : _vm._e(),
              _vm._v(" "),
              _vm._t("label", [_vm._v(_vm._s(_vm.label))])
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.layoutType === "horizontal"
        ? _c(
            "div",
            { class: _vm.inputClasses },
            [
              _vm._t("default"),
              _vm._v(" "),
              _c("v-form-error", {
                attrs: { error: _vm.error, errors: _vm.errors }
              })
            ],
            2
          )
        : [
            _vm._t("default"),
            _vm._v(" "),
            _c("v-form-error", {
              attrs: { error: _vm.error, errors: _vm.errors }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }