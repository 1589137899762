<template>
    <div class="cc-pdf">
        <div class="cc-pdf__toolbar">
            <button @click="changePage('prev')" class="btn btn-sm">&laquo;</button>
            <button @click="changePage()" class="btn btn-sm">&raquo;</button>
            <slot/>
        </div>
        <div id="cc-pdf__viewport-container">
            <div class="cc-pdf__viewport" ref="viewport"></div>
        </div>
    </div>
</template>
<script>
    let isLibLoaded = false;
    export default {
        name: 'cc-pdf',

        data() {
            const currentPageIndex = 0;
            const pageMode = 1;
            return {
                currentPageIndex: currentPageIndex,
                pageMode: pageMode,
                pdfInstance: null,
                totalPagesCount: 0,
                pdfData: null,
            };
        },

        props: {
            url: {
                type: String,
                required: true,
            },

            type: {
                type: String,
                default: 'link',
                validator(item) {
                    return ['link', 'base64'].indexOf(item);
                },
            },
        },

        computed: {
            viewportEl() {
                return this.$refs.viewport;
            },

            cursorIndex() {
                return Math.floor(this.currentPageIndex / this.pageMode);
            },

            pdfSource() {
                if (this.type === 'base64') {
                    return { data: this.pdfData };
                }

                return this.url;
            },
        },

        async mounted() {
            if (!isLibLoaded) {
                await this.loadLibrary();
            }

            if (this.type === 'base64') {
                await this.loadData();
            }

            this.init();
            this.$nextTick(() => {
                window.addEventListener('resize', this.renderPdf);
            });
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.renderPdf);
        },

        watch: {
            currentPageIndex() {
                this.renderPdf();
            },
        },

        methods: {
            loadLibrary() {
                return new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.onload = () => {
                        resolve();
                    };
                    script.async = true;
                    script.src = 'https://unpkg.com/pdfjs-dist@2.0.489/build/pdf.min.js';
                    document.head.appendChild(script);
                    isLibLoaded = true;
                });
            },

            init() {
                // eslint-disable-next-line no-undef
                pdfjsLib.getDocument(this.pdfSource).then(pdf => {
                    this.pdfInstance = pdf;
                    this.totalPagesCount = pdf.numPages;
                    this.renderPdf();
                });
            },

            async loadData() {
                const urlParams = new URLSearchParams(window.location.search);
                const response = await axios.get(`${this.url}?${urlParams.toString()}`);
                this.pdfData = atob(response.data.data);
            },

            renderPdf() {
                const startPageIndex = this.cursorIndex * this.pageMode;
                const endPageIndex = startPageIndex + this.pageMode < this.totalPagesCount ? startPageIndex + this.pageMode - 1 : this.totalPagesCount - 1;

                const renderPagesPromises = [];
                for (let i = startPageIndex; i <= endPageIndex; i++) {
                    renderPagesPromises.push(this.pdfInstance.getPage(i + 1));
                }

                Promise.all(renderPagesPromises).then(pages => {
                    const pagesHTML = `<div style="width: ${this.pageMode > 1 ? '50%' : '100%'};">
                        <canvas></canvas>
                    </div>`.repeat(pages.length);
                    // noinspection InnerHTMLJS
                    this.viewportEl.innerHTML = pagesHTML;
                    pages.forEach(this.renderPage);
                });
            },

            renderPage(page) {
                let pdfViewport = page.getViewport(1);
                const container = this.viewportEl.children[page.pageIndex - this.cursorIndex * this.pageMode];
                pdfViewport = page.getViewport(container.offsetWidth / pdfViewport.width);
                const canvas = container.children[0];
                const context = canvas.getContext('2d');
                canvas.height = pdfViewport.height;
                canvas.width = pdfViewport.width;

                page.render({
                    canvasContext: context,
                    viewport: pdfViewport,
                });
            },

            changePage(action = 'next') {
                let pageIndex = this.currentPageIndex;
                if (action === 'next') {
                    if (pageIndex === this.totalPagesCount - 1) {
                        return;
                    }

                    pageIndex += this.pageMode;
                    if (pageIndex > this.totalPagesCount - 1) {
                        pageIndex = this.totalPagesCount - 1;
                    }
                } else if (action === 'prev') {
                    if (pageIndex === 0) {
                        return;
                    }

                    pageIndex -= this.pageMode;
                    if (pageIndex < 0) {
                        pageIndex = 0;
                    }
                }

                this.currentPageIndex = pageIndex;
            },
        },
    };
</script>
