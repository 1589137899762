var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { ref: "modal", staticClass: "modal", attrs: { id: _vm.modalName } },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { "aria-label": "Close", type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.hide($event)
                    }
                  }
                },
                [_c("i", { staticClass: "icon icon--close" })]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body" },
                [_vm._t("default", null, null, this)],
                2
              )
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }