<script>
import Model from '../form/FormModel';

export default {
    name: 'cc-register-event',
    props: [
        'phName',
        'phSurname',
        'email',
        'phone',
        'details',
        'eventId',
    ],
    data() {
        return {
            form: new Model({
                name: '',
                surname: '',
                email: '',
                phone: '',
                details: '',
                event: '',
            }),
            submitting: false,
            sent: false,
            buttonClass: '',
            error: '',
        };
    },

    methods: {
        resetModal() {
          this.sent = false;
        },
        async submitEventRegister() {
            if (this.submitting) {
                return;
            }
            this.submitting = true;
            this.buttonClass = 'btn-loading';
            this.error = '';

            const data = {};
            data.event = this.$attrs.eventid;

            try {
                await axios.post('api/events/register', this.form.toFormData(data));
                this.sent = true;
                this.buttonClass = '';
            } catch (e) {
                this.form.$e.updateFromResponse(e);
                console.error(e);
                if (e.response.data.hasOwnProperty('error') && e.response.data.error.hasOwnProperty('exception')) {
                    this.error = e.response.data.error.message;
                    document.querySelector('.general-error').innerHTML = this.error;
                }
                this.buttonClass = '';
            }

            this.submitting = false;
        },
    },
};
</script>
