<script>
    import Swal from 'sweetalert2/dist/sweetalert2';

    export default {
        name: 'DeleteAccountForm',

        props: {
            title: {
                type: String,
                required: true,
            },
            text: {
                type: String,
                required: true,
            },
            confirmButton: {
                type: String,
                required: true,
            },
            cancelButton: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                submitting: false,
            };
        },

        methods: {
            submit() {
                Swal.fire({
                    title: this.title,
                    text: this.text,
                    showCancelButton: true,
                    confirmButtonText: this.confirmButton,
                    cancelButtonText: this.cancelButton,
                }).then((result) => {
                    if (result.value) {
                        this.sendDeleteRequest();
                    }
                });
            },

            sendDeleteRequest() {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;
                axios.delete('/api/user/delete').then(() => {
                    window.location = '/';
                }).finally(() => {
                    this.submitting = false;
                });
            },
        },
    };
</script>
