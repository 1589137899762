<script>

    export default {
        name: 'UnlockForm',
        props: {
            articleTranslationId: {
                type: Number,
                required: true,
            },
            loggedIn: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                subscribed_articles: [],
                submitting: false,
                data: {
                    unlocked_articles: null,
                    max_unlocks: null,
                },
                loaded: false,
            };
        },

        computed: {
            canUnlock() {
                return this.data.unlocked_articles < this.data.max_unlocks;
            },
        },

        async created() {
            if (this.loggedIn) {
                await this.load();
            }
        },

        methods: {
            async load() {
                const response = await axios.get('/api/user/unlocks');
                this.data = response.data.data;
                this.loaded = true;
            },

            async submit() {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;
                axios.post('/api/user/unlocks', { article_translation_id: this.articleTranslationId })
                    .then(() => {
                        window.location.reload();
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally(() => {
                        this.submitting = false;
                    });
            },
        },
    };
</script>
