<script>
    import Model from '../form/FormModel';

    export default {
        name: 'mailerLiteForm',

        data() {
            return {
                mailerLiteForm: new Model({
                    email: '',
                }),
                submitting: false,
                submitted: false,
                error_subcribe: false,
            };
        },

        methods: {
            async submitMailerLiteForm() {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;

                try {
                    await axios.post('/api/mailerlite/add-subscriber', this.mailerLiteForm.toFormData({}));
                    this.submitted = true;
                } catch (e) {
                    console.error(e);
                    const response = e.response;
                    if (response.status && response.status === 404) {
                        this.error_subcribe = true;
                    } else {
                        this.mailerLiteForm.$e.updateFromResponse(e);
                    }
                }

                this.submitting = false;
            },
        },
    };
</script>
