import cloneDeep from 'lodash/cloneDeep';
import Keys from './Keys';

function _pathWalker(parent, pendingPath, callback, objectPath = []) {
    let target = parent;
    const length = pendingPath.length;
    const path = [...objectPath];

    for (let i = 0; i < length; i++) {
        const name = pendingPath[i];

        if (name === '*') {
            for (let j = 0; j < target.length; j++) {
                if (i + 1 === length) {
                    callback(target[j], j, target, [...path, j].join('.'));
                } else {
                    _pathWalker(target[j], pendingPath.slice(i + 1), callback, [...path, j]);
                }
            }
            return;
        }

        if (i + 1 === length) {
            callback(target[name], name, target, [...path, name].join('.'));
            return;
        }

        path.push(name);
        target = target[name];
    }
}

/**
 * Walk object path.
 * @param {*} object
 * @param {String} path
 * @param {Function} callback
 */
export function walkDataPath(object, path, callback) {
    _pathWalker(object, path.split('.'), callback);
}

function _executeCloneDataAction(object, actions, keys = null) {
    for (const path in actions) {
        if (!actions.hasOwnProperty(path)) {
            continue;
        }

        const ID = keys || new Keys();
        const action = actions[path];
        let executor = null;

        if (typeof action === 'function') {
            executor = action;
        } else if (typeof action === 'object') {
            executor = function (value) {
                // In case the expression is fields.*, we pass in existing ID instance to avoid duplicate keys
                _executeCloneDataAction(value, action, ID);
            };
        } else {
            if (action === 'delete') {
                executor = function (value, key, parent) {
                    delete parent[key];
                };
            } else if (action === '-key') {
                executor = function (value, key, parent) {
                    parent[key] = ID.nextNegative();
                };
            } else if (action === '+key') {
                executor = function (value, key, parent) {
                    parent[key] = ID.nextPositive();
                };
            } else if (action.startsWith('pluck:')) {
                const pluckKey = action.substr(6);

                executor = function (value, key, parent) {
                    parent[key] = value.map(item => item[pluckKey]);
                };
            } else {
                console.warn(`Invalid clone data action - "${action}".`);
            }
        }

        if (executor) {
            walkDataPath(object, path, executor);
        }
    }
}

/**
 * Clone data.
 * @param {*} object
 * @param {*} actions
 * @return {*}
 */
export function cloneData(object, actions = null) {
    const clone = cloneDeep(object);

    if (actions) {
        _executeCloneDataAction(clone, actions);
    }

    return clone;
}

/**
 * Get object size.
 * @param {Array|Object} object
 * @returns {Number}
 */
export function dataLength(object) {
    if (!object) {
        return 0;
    }

    if (Array.isArray(object)) {
        return object.length;
    }

    return Object.keys(object).length;
}

/**
 * Generator for iterating array or object.
 * @param {Array|Object} object
 */
export function* dataIterator(object) {
    if (Array.isArray(object)) {
        // eslint-disable-next-line guard-for-in
        for (const key in object) {
            yield [key, object[key]];
        }
    } else {
        for (const key in object) {
            if (!object.hasOwnProperty(key)) {
                continue;
            }

            yield [key, object[key]];
        }
    }
}
