<script>
    import Model from '../../form/FormModel';

    export default {
        name: 'changePasswordForm',

        data() {
            return {
                changePasswordForm: new Model({
                    old_password: '',
                    new_password: '',
                    new_password_confirmation: '',
                }),
                submitting: false,
                submitted: false,
            };
        },


        methods: {
            async submitChangePassword() {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;

                try {
                    await axios.post(`/api/user/change-password?lang=${document.documentElement.lang}`, this.changePasswordForm.toFormData());
                    this.submitted = true;
                    this.changePasswordForm.$e.clear();
                } catch (e) {
                    console.error(e);
                    this.changePasswordForm.$e.updateFromResponse(e);
                }

                this.submitting = false;
            },

        },
    };
</script>
