<script>
import Model from '../form/FormModel';

export default {
    name: 'CartForm',
    props: {
        subscriptions: {
            type: Array,
        },
        course: {
            type: Object,
        },
        learningPath: {
            type: Object,
        },
        cartUrl: {
            type: String,
        },
        loggedIn: {
            type: String,
        },
        currencyRate: {
            type: Number,
        },
    },
    data() {
        return {
            discountForm: new Model({
                code: null,
            }),
            submitting: false,
            submitted: false,
            subscription: (this.subscriptions && this.subscriptions.length) ? this.subscriptions[0] : null,
            discountApplied: false,
            error: null,
            currency: 'EUR',
        };
    },

    computed: {
        items() {
            return this.cart.items;
        },

        cart() {
            return this.$store.state.cart;
        },

        subscriptionItem() {
            if (this.subscription) {
                const category = this.subscription.category_title ? `${this.subscription.category_title}:` : '';
                return {
                    item_id: this.subscription.id,
                    item_type: 'subscription',
                    title: `${category} ${this.subscription.title}`,
                };
            }
            return null;
        },

        courseItem() {
            if (this.course) {
                const category = this.course.category_title ? `${this.course.category_title}:` : '';
                return {
                    item_id: this.course.translation_id,
                    item_type: 'course-translation',
                    title: `${category} ${this.course.title}`,
                };
            }
            return null;
        },

        learningPathItem() {
            if (this.learningPath) {
                const category = this.learningPath.category_title ? `${this.learningPath.category_title}:` : '';
                return {
                    item_id: this.learningPath.translation_id,
                    item_type: 'learning-path-translation',
                    title: `${category} ${this.learningPath.title}`,
                };
            }
            return null;
        },

        subscriptionPrice() {
            if (this.subscription) {
                return (this.subscription.monthly_price / 100).toFixed(2);
            }

            return 0;
        },

        total() {
            return this.cart.total;
        },

        count() {
            return this.items.length;
        },

        loading() {
            return this.cart.loading;
        },

        discount() {
            return this.cart.discount;
        },

        hasDiscount() {
            return this.cart.discount && this.cart.discount.amount > 0;
        },

        activeCurrency() {
            return { key: 'EUR', symbol: '€' };
        },
    },

    methods: {
        async buyNowCourse() {
            if (!this.loggedIn) {
                document.dispatchEvent(new Event('modal-open:auth-modal'));
                document.addEventListener('authenticated', async () => {
                    await axios.post('/api/cart/add-item', this.courseItem());
                    window.location.href = this.cartUrl;
                });
                return;
            }

            if (this.submitting) {
                return;
            }

            this.submitting = true;

            try {
                await axios.post('/api/cart/add-item', this.courseItem)
                    .then((res) => {
                        this.$store.commit('setCart', res.data.data);
                        this.redirectToCheckout();
                    });
            } catch (e) {
                console.error(e);
                if (e.response.status === 422) {
                    this.$store.commit('showCartMessage', {
                        vm: this,
                        template: 'already-in-cart',
                    });
                }
            }

            this.submitting = false;
        },

        async buyNowLearningPath() {
            if (!this.loggedIn) {
                document.dispatchEvent(new Event('modal-open:auth-modal'));
                document.addEventListener('authenticated', async () => {
                    await axios.post('/api/cart/add-item', this.learningPathItem);
                    window.location.href = this.cartUrl;
                });
                return;
            }

            if (this.submitting) {
                return;
            }

            this.submitting = true;

            try {
                await axios.post('/api/cart/add-item', this.learningPathItem)
                    .then((res) => {
                        this.$store.commit('setCart', res.data.data);
                        this.redirectToCheckout();
                    });
            } catch (e) {
                console.error(e);
                if (e.response.status === 422) {
                    this.$store.commit('showCartMessage', {
                        vm: this,
                        template: 'already-in-cart',
                    });
                }
            }

            this.submitting = false;
        },

        async subscribeNow(subscriptionId) {
            if (subscriptionId) {
                this.selectSubscription(subscriptionId);
            }
            if (!this.loggedIn) {
                document.addEventListener('authenticated', async () => {
                    await axios.post('/api/cart/add-item', this.subscriptionItem);
                    window.location.href = this.cartUrl;
                });
                document.dispatchEvent(new Event('modal-open:auth-modal'));
                return;
            }

            if (this.submitting) {
                return;
            }

            this.submitting = true;

            try {
                await axios.post('/api/cart/add-item', this.subscriptionItem)
                    .then(() => {
                        this.$store.commit('getCart');
                        this.redirectToCheckout();
                    });
            } catch (e) {
                console.error(e);
                if (e.response.status === 422) {
                    this.$store.commit('showCartMessage', {
                        vm: this,
                        template: 'already-in-cart',
                    });
                }
            }

            this.submitting = false;
        },

        selectSubscription(id) {
            this.subscription = this.subscriptions.find(sub => sub.id === id);
        },

        async addSubscription() {
            this.addItem(this.subscriptionItem, 'add-subscription-item');
        },

        async addLearningPath() {
            this.addItem(this.learningPathItem, 'add-learning-path-item');
        },

        async addCourse() {
            this.addItem(this.courseItem, 'add-course-item');
        },

        async addItem(item, template) {
            if (this.submitting) {
                return;
            }

            this.submitting = true;

            try {
                await axios.post('/api/cart/add-item', item)
                    .then((res) => {
                        this.$store.commit('setCart', res.data.data);
                        this.$store.commit('showCartMessage', {
                            vm: this,
                            template,
                        });
                    });
            } catch (e) {
                console.error(e);
                if (e.response.status === 422) {
                    this.$store.commit('showCartMessage', {
                        vm: this,
                        template: 'already-in-cart',
                    });
                }
            }

            this.submitting = false;
        },

        async removeItem(item) {
            if (this.submitting) {
                return;
            }

            this.submitting = true;

            try {
                await axios.post('/api/cart/remove-item', item)
                    .then((res) => {
                        this.$store.commit('setCart', res.data.data);
                    });
            } catch (e) {
                console.error(e);
                this.instructorForm.$e.updateFromResponse(e);
            }

            this.submitting = false;
        },

        updateCartItemAmount(item, e) {
            clearTimeout(item.updateTimeout);
            item.updateTimeout = setTimeout(() => {
                axios.post('/api/cart/update-amount', {
                        item_id: item.id,
                        amount: e.target.value,
                    })
                    .then((res) => {
                        this.$store.commit('setCart', res.data.data);
                    })
                    .catch(() => {
                        this.$store.commit('getCart');
                    });
            }, 300);
        },

        async applyDiscount() {
            if (this.submitting) {
                return;
            }

            this.discountApplied = false;
            this.submitting = true;
            this.discountForm.$e.clear();
            try {
                await axios.post('/api/cart/apply-discount', this.discountForm.toFormData())
                    .then((res) => {
                        this.discountApplied = true;
                        this.$store.commit('setCart', res.data.data);
                    });
            } catch (e) {
                console.error(e);
                this.discountForm.$e.updateFromResponse(e);
            }

            this.submitting = false;
        },

        redirectToCheckout() {
            if (this.loggedIn) {
                window.location.href = this.cartUrl;
            } else {
                document.dispatchEvent(new Event('modal-open:auth-modal'));
            }
        },

        getPrice(cents, digits = 2) {
            let price = cents;

            price /= 100;
            price = parseFloat(price.toFixed(digits));

            return `${this.activeCurrency.symbol}${price}`;
        },
    },
};
</script>
