<template>
    <a :href="href" class="notifications__holder">
        <i class="icon icon--bell"></i>
        <span v-if="count" class="notifications__count">{{ count }}</span>
    </a>
</template>

<script>
    export default {
        name: 'cc-notification-indicator',

        data() {
            return {
                count: 0,
            };
        },

        props: {
            href: {
                type: String,
                default: '',
            },
        },

        created() {
            this.load();
        },

        methods: {
            async load() {
                const response = await axios.get('/api/notifications/notification-count');
                this.count = response.data.data.notification_count;
            },
        },
    };
</script>
