export class Popup {
    /**
     * Popup constructor.
     */
    constructor() {
        this._opened = null;
        this._lastOpened = null;

        document.body.addEventListener('click', event => this._documentClick(event));
    }

    /**
     * Popup has opened.
     * @param {*} comp
     * @param {Function} closeFn
     */
    opened(comp, closeFn) {
        if (this._opened && this._opened.target !== comp) {
            this.closeLast();
            this.close();
        }

        this._lastOpened = this._opened;
        this._opened = {
            target: comp,
            close: closeFn,
        };
    }

    /**
     * Popup has closed.
     * @param {*} comp
     */
    closed(comp) {
        if (this._opened && this._opened.target === comp) {
            this._opened = null;
            this._lastOpened = null;
        }
    }

    /**
     * Close last opened popup.
     */
    closeLast() {
        if (this._lastOpened !== null) {
            this._lastOpened.close();
        }

        this._lastOpened = null;
    }

    /**
     * Close currently opened popup.
     */
    close() {
        if (this._opened) {
            this._opened.close();
        }

        this._opened = null;
    }

    /**
     * Handle document click.
     * @private
     */
    _documentClick() {
        if (this._opened === null) {
            this._lastOpened = null;
            return;
        }

        this.close();
    }
}

export default new Popup();
