/**
 * Replace variables in string.
 * @param {String} string
 * @param {Object} variables
 * @param {String} prefix
 * @param {String} suffix
 * @returns {*}
 */
export function replaceVariables(string, variables, prefix = '{', suffix = '}') {
    let result = string;

    Object.keys(variables).forEach(key => {
        result = result.replace(prefix + key + suffix, variables[key]);
    });

    return result;
}

/**
 * Convert string to Number.
 * @param {String} value
 * @param {*} def
 * @returns {*}
 */
export function stringToBoolean(value, def = null) {
    if ([true, 1, '1', 'true'].indexOf(value) >= 0) {
        return true;
    } else if ([false, 0, '0', 'false'].indexOf(value) >= 0) {
        return false;
    }

    return def;
}

/**
 * Convert string to Number.
 * @param {String} value
 * @param {*} def
 * @returns {*}
 */
export function stringToNumber(value, def = null) {
    if (isNaN(Number(value))) {
        return def;
    }

    return Number(value);
}

/**
 * Get position of a term in string.
 * @param {String} string
 * @param {String} term
 * @returns {Number}
 */
export function indexOfCi(string, term) {
    return string.toLowerCase().indexOf(term.toLowerCase());
}

/**
 * Highlight a portion of the string.
 * @param {String} string
 * @param {String} term
 * @param {String} opening
 * @param {String} closing
 * @return {String}
 */
export function highlight(string, term, opening = '<b>', closing = '</b>') {
    if (!term) {
        return string;
    }

    const searchStartIndex = indexOfCi(string, term);

    // Term not found
    if (searchStartIndex < 0) {
        return string;
    }

    let build = string;

    // First insert ending </b> to avoid modifying start index
    build = build.substring(0, searchStartIndex + term.length) + closing + build.substring(searchStartIndex + term.length);

    // Insert beginning <b>
    build = build.substring(0, searchStartIndex) + opening + build.substring(searchStartIndex);

    return build;
}

/**
 * Format string.
 * @param {String} format
 */
export function format(format) {
    const args = Array.prototype.slice.call(arguments, 1);
    return format.replace(/{(\d+)}/g, (match, number) => typeof args[number] === 'undefined' ? match : args[number]);
}
