<template>
    <form :class="{ 'form-horizontal': layout === 'horizontal' }" @submit.prevent="submit">
        <slot></slot>
    </form>
</template>

<script>
export default {
    name: 'form-wrapper',

    props: {
        layout: {
            type: String,
            default: 'horizontal',
            validator(value) {
                return ['horizontal', 'vertical'].indexOf(value) >= 0;
            },
        },

        action: {
            type: String,
            default: null,
        },

        errors: null,
    },

    provide() {
        return {
            form: this,
        };
    },

    methods: {
        submit() {
            this.$emit('submit', this.action);
        },
    },
};
</script>
