<script>
import Model from '../../../form/FormModel';

export default {
    name: 'cc-license-purchase-course-form',

    props: {
        cartUrl: {
            required: true,
            type: String,
        },

        courseListUrl: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            model: new Model({
                course: null,
                amount: null,
            }),
            meta: {
                courses: [],
            },
            submitting: false,
        };
    },

    created() {
        this.loadCourses();
    },

    methods: {
        async loadCourses(val) {
            const params = {
                value: val,
            };

            const response = await axios.get(this.courseListUrl, {
                params: params,
            });

            this.meta.courses = response.data.data;
        },

        async submit(url) {
            if (this.submitting) {
                return;
            }

            this.submitting = true;
            this.success_message = null;
            this.model.$e.clear();

            try {
                await axios.post(url, this.model.$);
                this.model.clear();
                window.location.href = this.cartUrl;
            } catch (e) {
                this.model.$e.updateFromResponse(e);
                this.submitting = false;
            }
        },
    },
};
</script>
