<script>
    import Model from '../../form/FormModel';

    export default {
        name: 'preferencesForm',

        inject: { modalData: { default: null } },

        data() {
            return {
                model: new Model({
                    languages: [],
                    markets: [],
                    preferences: {
                        newsletter_language: '',
                        newsletter_frequency: '',
                    },
                }),
                meta: {
                    frontend_languages: [],
                    languages: [],
                    markets: [],
                    newsletter_frequencies: [],
                    show_subscribe_button: false,
                },
                selectedLanguage: '',
                selectedMarket: '',
                submitting: false,
                submitted: false,
                modalOpenEvent: this.modalData ? new CustomEvent('modal-open:user-preferences', { detail: {} }) : null,
                modalCloseEvent: this.modalData ? new CustomEvent('modal-close:user-preferences') : null,
            };
        },

        async created() {
            await this.load();
        },

        mounted() {
            if (this.modalOpenEvent) {
                document.dispatchEvent(this.modalOpenEvent);
            }
        },

        computed: {
            availableLanguages() {
                return this.meta.languages.filter(language => this.model.$.languages.indexOf(language.id) === -1);
            },

            availableMarkets() {
                return this.meta.markets.filter(market => this.model.$.markets.indexOf(market.id) === -1);
            },

            selectedLanguages() {
                return this.model.$.languages.map(selected => this.meta.languages.find(lang => lang.id === selected).title);
            },

            selectedMarkets() {
                return this.model.$.markets.map(selected => this.meta.markets.find(market => market.id === selected).title);
            },

            allMarketsSelected() {
                return this.model.$.markets.length === this.meta.markets.length;
            },
        },

        methods: {
            async load() {
                const response = await axios.get(`/api/profile/preferences?lang=${document.documentElement.lang}`);
                this.meta = response.data.meta;
                this.model.setPristine(response.data.data);
            },

            addLanguage() {
                if (!this.selectedLanguage) {
                    return;
                }

                this.model.$.languages.push(this.selectedLanguage);
                this.selectedLanguage = '';
            },

            addMarket() {
                if (!this.selectedMarket) {
                    return;
                }

                this.model.$.markets.push(this.selectedMarket);
                this.selectedMarket = '';
            },

            languageSelect() {
                this.addLanguage();
            },

            marketSelect() {
                this.addMarket();
            },

            removeLanguage(index) {
                this.model.$.languages.splice(index, 1);
            },

            removeMarket(index) {
                this.model.$.markets.splice(index, 1);
            },

            selectAllMarkets() {
                this.model.$.markets = this.meta.markets.map(item => item.id);
            },

            unselectAllMarkets() {
                this.model.$.markets = [];
            },

            async submit() {
                if (this.submitting) {
                    return;
                }

                this.submitted = false;
                this.submitting = true;
                this.model.$e.clear();

                try {
                    await axios.put(`/api/profile/preferences?lang=${document.documentElement.lang}`, this.model.$);
                    this.submitted = true;
                    this.closeModal();
                } catch (e) {
                    this.model.$e.updateFromResponse(e);
                    console.error(this.model.$e);
                }

                this.submitting = false;
            },

            async subscribe() {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;
                axios.post('/api/user/subscribe')
                    .then(() => {
                        this.meta.show_subscribe_button = false;
                    })
                    .catch(e => {
                        console.error(e);
                    })
                    .finally(() => {
                        this.submitting = false;
                    });
            },

            closeModal() {
                if (this.modalCloseEvent) {
                    document.dispatchEvent(this.modalCloseEvent);
                }
            },
        },
    };
</script>
