<template>
    <div class="form-group">
        <!-- label -->
        <label :class="labelClasses" class="control-label" v-if="showLabel">
            <span class="text-danger" v-if="required">*</span>
            <slot name="label">{{ label }}</slot>
        </label>

        <!-- inputs -->
        <div :class="inputClasses" v-if="layoutType === 'horizontal'">
            <slot></slot>
            <v-form-error :error="error" :errors="errors"></v-form-error>
        </div>
        <template v-else>
            <slot></slot>
            <v-form-error :error="error" :errors="errors"></v-form-error>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'form-group',

        inject: {
            form: {
                default: null,
            },
        },

        props: {
            label: {
                type: String,
            },
            errors: null,
            error: {
                type: String,
            },
            labelClass: {
                type: String,
                default: 'col-sm-3 col-lg-2',
            },
            inputClass: {
                type: String,
                default: 'col-sm-9 col-lg-10',
            },
            layout: {
                type: String,
                default: 'inherit',
                validator(value) {
                    return ['inherit', 'horizontal', 'vertical'].indexOf(value) >= 0;
                },
            },
            required: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            showLabel() {
                return typeof this.label === 'string' || this.$slots.label;
            },

            layoutType() {
                if (this.layout !== 'inherit') {
                    return this.layout;
                }

                if (this.form) {
                    return this.form.layout;
                }

                return 'horizontal';
            },

            labelClasses() {
                const list = [];

                if (this.layoutType === 'horizontal') {
                    list.push(this.labelClass);
                }

                return list;
            },

            inputClasses() {
                const list = [];

                if (this.layoutType === 'horizontal' && this.showLabel) {
                    list.push(this.inputClass);
                }

                return list;
            },
        },
    };
</script>
